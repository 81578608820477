import NotificationComponent from '../../Components/NotificationComponent';
import BookMarkIcon from '../../Assets/Icons/BookmarkIcon.svg';
import LocationIcon from '../../Assets/Icons/LocationIcon.svg';
import WorkSuitcaseIcon from '../../Assets/Icons/material-symbols_work-outline.svg';
import CalenderIcon from '../../Assets/Icons/calendar-linear.svg';
import ClockIcon from '../../Assets/Icons/clockIcon.svg'
import { useNavigate } from 'react-router-dom';
import { OptionType, PosReqData, PosReqData2, PositionDetails, SkillData, jobSearch } from '../../types';
import { useEffect, useState } from 'react';
import Helper from '../../utils/helper';
import DefaultComapnayImage from '../../Assets/Images/Image20240129183323.png';
import { EXPERIENCE_LEVEL, OFFICE_POLICY, SORT_BY, TIMEZONES, countries, customStyles } from '../../utils/constant';
import useAuth from '../../hooks/useAuth';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { CiFilter } from "react-icons/ci";
import SwitchOnImage from "../../Assets/Icons/switchOn.png";
import SwitchOffImage from "../../Assets/Icons/switchoff.png";
import FilterSideebar from '../../Components/Candidate/Modals/FilterSideebar';
import UnBookMarkIcon from '../../Assets/Icons/bookmark-close-icon.svg';
import LottieAnimation from '../../Components/LottieAnimation';
import InitialsAvatar from 'react-initials-avatar';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import EmpNotificationComponent from '../../Components/EmpNotificationComponent';

const EmployeeAllJobsPage = () => {

    const searchInit: jobSearch = {
        positionIds: [],
        skills: [],
        optionalSkills: [],
        officePolicy: [],
        location: [],
        search: '',
        showBookmarked: false,
        isShowMatchingOnly: false
    }

    const [positions, setPositions] = useState<OptionType[]>([]);
    const [positionReqs, setPositionReqs] = useState<PosReqData2[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const helper = Helper.getInstance();
    const [search, setSearch] = useState<jobSearch>(searchInit);
    const [searchStr, setSearchStr] = useState<string>('');
    const [bookmarked, setBookmarked] = useState<number[]>([]);
    const { user, getDetails } = useAuth();
    const [skillsDB, setSkillsDB] = useState<OptionType[]>([]);

    useEffect(() => {
        getDetails(false);
    }, [])

    useEffect(() => {
        if (user) {
            getPositionReqs();
            getPositionDetails();
            getSkillsDataDB();
            setBookmarked(user.jobsBookmarked || []);
        };
        // } else if (user && !user.isAllSkillsVetted) {
        //     toast.error('Complete Core Skills GPT Vetting First');
        //     navigate(`/candidate-gpt-vetting`, { replace: true });
        // }
    }, [user]);

    const getPositionReqs = async (search: jobSearch = searchInit) => {
        try {
            setLoading(true)
            const res_data = await helper.postReq(helper.POST.ALL_JOBS, search);
            const { data } = res_data.data;

            if (data) setPositionReqs(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getPositionDetails = async () => {
        try {
            setLoading(true)
            const res_data = await helper.getReq(helper.GET.POSITIONS);
            const { data } = res_data.data;

            const mapped: OptionType[] = data.map((dt: PositionDetails) => ({ label: dt.position, value: dt.id }));
            if (data) setPositions(mapped);
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    const getSkillsDataDB = async () => {
        try {
            const res_data = await helper.getReq(helper.GET.SKILLS_DATA);
            const { data } = res_data.data;
            if (data) {
                const mapped: OptionType[] = data.map((sk: SkillData) => ({ label: sk.skill, value: sk.skill }));
                setSkillsDB(mapped);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getOptionLabel = (val: string | number, opArr: any[]) => {
        const op = opArr.find((x) => x.value == val);

        if (op) return op.label;

        return '';
    };

    const navigate = useNavigate();
    const handleViewDetailsButton = (id: number) => {
        navigate(`/candidate-view-jobs/${id}`, { replace: true });
    };

    const getMultiSelectedDef = (dataArr: OptionType[], dataVal: number[] | string[]) => {
        if (!dataVal.length) return;

        const jdataArr: OptionType[] = JSON.parse(JSON.stringify(dataArr));
        const newArr = [];

        for (let d of dataVal) {
            const val = jdataArr.filter((dt) => dt.value == d);

            if (val.length) newArr.push(val[0]);
        };

        return newArr;
    };

    const addBookMark = async (id: number, isBookMarked: boolean) => {
        try {
            setLoading(true);
            const uData = { positionReqId: id, isBookMarked };
            const res_data = await helper.putReq(helper.PUT.BOOKMARK, uData);
            const { data } = res_data.data;

            if (data) {
                setBookmarked(data);
                getPositionReqs(search);
            } else {
                setLoading(false);
                throw new Error("Failed");
            }
        } catch (error) {
            console.log(error);
            toast.error('Update Details Failed');
        }
        setLoading(false);
    };

    if (!user) return <p>Loading...</p>

    const handleToogleButtonSavJobs = () => {
        getPositionReqs({ ...search, isShowMatchingOnly: !search.isShowMatchingOnly });
        setSearch({ ...search, isShowMatchingOnly: !search.isShowMatchingOnly });
    };


    const applyFilter = async (data: jobSearch, autoClick: boolean = true) => {
        getPositionReqs(data);
        setSearch(data);
        if (autoClick) document.getElementById('toggleFilterCanvas')?.click();
    };


    // { console.log(positionReqs.length == 0) }

    return (
        <>
            <div id="content" className='pt-5'>
                <div className="container-fluid">
                    <div className='top-navigation mb-5'>
                        <EmpNotificationComponent />
                        <h2>All Jobs</h2>
                        <p >Here you can find high paying jobs matching to your skills</p>
                    </div>


                    {loading ? <LottieAnimation /> : (<>
                        <div className='d-flex justify-content-between align-items-center flex-wrap mb-5'>
                            <div>
                                <input
                                    type='text'
                                    name='search'
                                    id='search'
                                    className='sarchByRolesandSkills-cst'
                                    placeholder="Search by role or skills"
                                    value={searchStr}
                                    onChange={(ev) => setSearchStr(ev.target.value)}
                                    // onChange={(ev) => applyFilter({ ...search, search: ev.target.value }, false)}
                                />
                                <button 
                                    onClick={() => applyFilter({ ...search, search: searchStr }, false)} 
                                    className='btn add-filter-right-button text-center img-fluid btn-add-dropdwon-cst'
                                    >Search
                                </button>
                            </div>
                            <div className='d-flex justify-content-between filter-two-buttons' style={{ gap: "16px" }}>
                                <div className='details-card-for-input cst-ptr'>
                                    <button id='toggleFilterCanvas' className='btn add-filter-right-button text-center img-fluid btn-add-dropdwon-cst' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                        <CiFilter />&nbsp;Filters &nbsp;
                                    </button>
                                </div>

                                <div className='details-card-for-input cst-ptr'>
                                    {/* {search.isShowMatchingOnly ? <> */}
                                        <button onClick={handleToogleButtonSavJobs} className='btn add-filter-right-button text-center img-fluid btn-add-dropdwon-cst'>
                                            Matching Jobs&nbsp;<img src={search.isShowMatchingOnly ? SwitchOnImage : SwitchOffImage} />
                                        </button>
                                    {/* </> : <>
                                        <button onClick={handleToogleButtonSavJobs} className='btn add-filter-right-button text-center img-fluid btn-add-dropdwon-cst'>
                                            Show matching jobs&nbsp;<img src={SwitchOffImage} />
                                        </button>
                                    </>} */}
                                </div>
                            </div>
                        </div>

                        {positionReqs.map((posReq) => (
                            <div className='row employee-white-card align-items-center p-4 mb-4' key={posReq.id}>
                                <div className='col-lg-1 col-md-2 col-12'>
                                    <div>
                                        {/* <img src={DefaultComapnayImage} className='img-fluid' style={{ borderRadius: "50%" }} /> */}
                                        {posReq.company.imageUrl ?
                                            <img src={posReq.company.imageUrl || DefaultComapnayImage} className='img-fluid' style={{ borderRadius: "50%" }} /> :
                                            <InitialsAvatar name={posReq.company.name} />
                                        }
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-3 col-12'>
                                    <div>
                                        <label className='cst-secondary-color'>Job Role</label>
                                        <h3>{getOptionLabel(posReq.positionId || 1, positions)}</h3>

                                        <label className='cst-secondary-color'>Company</label>
                                        <h3
                                            className="blurred"
                                            contentEditable={false}
                                            onMouseDown={(e) => e.preventDefault()}
                                            onContextMenu={(e) => e.preventDefault()}
                                            onSelect={(e) => e.preventDefault()}
                                        >{posReq.company.name}</h3>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-3 col-12'>
                                    <div>
                                        <label className='cst-secondary-color'>Location</label>
                                        <h4><img src={LocationIcon} /> {getOptionLabel(posReq.officePolicy || 1, OFFICE_POLICY)} • {posReq.company.country}</h4>

                                        <label className='cst-secondary-color'>Experience Required</label>
                                        <h4><img src={WorkSuitcaseIcon} /> {getOptionLabel(posReq.seniority || 1, EXPERIENCE_LEVEL)}</h4>
                                    </div>
                                </div>
                                <div className='col-lg-2 col-md-3 col-12'>
                                    <div>
                                        <label className='cst-secondary-color'>Shift Time</label>
                                        <h4><img src={ClockIcon} />&nbsp;{posReq.timezone}</h4>

                                        <label className='cst-secondary-color'>Notice Period</label>
                                        <h4><img src={CalenderIcon} /> 2 Weeks</h4>
                                    </div>
                                </div>
                                <div className='col-lg-2 col-md-4 col-12 mt-3 '>
                                    <button className='btn view-details-btn px-4' onClick={() => handleViewDetailsButton(posReq.id)} disabled={loading}>View Details</button>
                                </div>
                                {bookmarked?.includes(posReq.id) ? (
                                    <div className='col-lg-1 col-md-2 col-12 mt-3'>
                                        <img src={UnBookMarkIcon} className='img-fluid cst-ptr' onClick={() => addBookMark(posReq.id, false)} />
                                    </div>
                                ) : (
                                    <div className='col-lg-1 col-md-2 col-12 mt-3'>
                                        <img src={BookMarkIcon} className='img-fluid cst-ptr' onClick={() => addBookMark(posReq.id, true)} />
                                    </div>
                                )}

                            </div>
                        ))}
                    </>)}
                </div>
            </div>
            {skillsDB && <FilterSideebar
                positions={positions}
                applyFilter={applyFilter}
                searchM={search}
                skillsDB={skillsDB}
            />}
        </>
    )
}

export default EmployeeAllJobsPage