import React, { useEffect, useState } from 'react'
import NotificationIcon from '../Assets/Icons/notificationIcon.svg'
import WordpressIcon from '../Assets/Icons/Ellipse 526.png'
import Helper from '../utils/helper';
import useAuth from '../hooks/useAuth';
import { NotificationData } from '../types';
import DefaultImage from '../Assets/Images/Image20240129183323.png';
import TimeAgo from './TimeAgo';
import { Link } from 'react-router-dom';
import NotificationMobileIcon from '../Assets/Icons/mobileNotification.svg'

const NotificationComponent = () => {

    const [notifications, setNotifications] = useState<NotificationData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const helper = Helper.getInstance();
    const { user } = useAuth()

    useEffect(() => {
        if (user) getNotifications();
    }, [user]);

    const getNotifications = async () => {
        try {
            setLoading(true);
            const res_data = await helper.getReq(helper.GET.EMPLOYER_NOTIFICATION);
            const { data } = res_data.data;

            if (data) setNotifications(data);

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    return (
        <>
            <div className="dropdown">
                <img src={NotificationIcon} className='img-fluid cst-ptr d-md-block d-none btn-add-dropdwon-cst dropdown-toggle' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{ float: 'right' }} />
                {/* <img src={NotificationMobileIcon} className='img-fluid cst-hide-fot-tab  btn-add-dropdwon-cst dropdown-toggle' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" /> */}
                <ul className="dropdown-menu cst-width-for-taba-and-all" aria-labelledby="dropdownMenuButton1">
                    <li className="px-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <h4>Notifications</h4>
                            <h6>Clear all</h6>
                        </div>
                        <hr />
                    </li>
                    {notifications.map((nt: NotificationData) => (
                        <Link to={nt.navigatePath || '#'} key={nt.id}>
                            <div className="cst-dropdown-hover" >
                                <div className="d-flex justify-content-between align-items-center">
                                    <img src={nt.dataImageURL || DefaultImage} className="img fluid" style={{ height: '50px', width: '50px' }} />
                                    <div className='d-flex' style={{ flexDirection: 'column', width: "68%" }}>
                                        <label className="cst-primary-color">{nt.heading || 'Job Application Status'}</label>
                                        {/* <h5>Jobs with your matching Skillset</h5> */}
                                        <h6>{nt.message}</h6>
                                    </div>
                                    <h6><TimeAgo date={new Date(nt.createdAt)} /></h6>
                                </div>
                            </div>
                        </Link>

                    ))}
                    {/* <div className="cst-dropdown-hover">
                        <div className="d-flex justify-content-between align-items-center">
                            <img src={WordpressIcon} className="img fluid" />
                            <div className='d-flex' style={{ flexDirection: 'column' }}>
                                <label className="cst-primary-color">Job Application Status</label>
                                <h5>Jobs with your matching Skillset</h5>
                                <h6>250+ jobs have been ad go ahead and check</h6>
                            </div>
                            <h6>1 Days ago</h6>
                        </div>
                    </div> */}

                </ul>
            </div>
        </>
    )
}

export default NotificationComponent