import MapPinIcon from '../../../Assets/Icons/map-pin.svg'
import LaunguageIcon from '../../../Assets/Icons/tabler_language.svg'
import CloseIcon from '../../../Assets/Icons/close-bold-icon.png'
import DotImg from '../../../Assets/Icons/EllipseDot.svg'
import { EmpProfileDetails, PositionDetails, RequestForInterviewModelProps, ScheduleInterview, SkillData } from '../../../types'
import React, { useState, useEffect } from 'react'
import useAuth from '../../../hooks/useAuth'
import { APPLICATION_STATUS, EXPERIENCE_LEVEL, countries, languagesOptions } from '../../../utils/constant'
import Helper from '../../../utils/helper'
import DefaultImage from '../../../Assets/Icons/default.png';
import DownlodIcon from '../../../Assets/Icons/downlodIcon.png'
import InfoVectorImg from '../../../Assets/Icons/infoVector.png'
import { toast } from 'react-toastify'


import { Tooltip as ReactTooltip } from "react-tooltip";
import RequestOutSourceInterview from './RequestOutSourceInterview'
// import ReactDatePicker from 'react-datepicker'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const RequestForInterviewModel = ({ employeeId, positions, applicationId, interviewDates, posData }: RequestForInterviewModelProps) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [loading2, setLoading2] = useState<boolean>(false);
    const [isLink, setIsLink] = useState<boolean>(true);

    const helper = Helper.getInstance();
    const [appId, setAppId] = useState<number>();
    const [empId, setEmpId] = useState<number>();
    const [profile, setProfile] = useState<EmpProfileDetails>();
    const [isRequestInt, setIsRequestInt] = useState<boolean>(false);

    const initData: ScheduleInterview = {
        interviewScheduledAt1: null,
        interviewScheduledAt2: null,
        interviewScheduledAt3: null,
        calendarLink: ''
    };

    const [scheduleInt, setScheduleInt] = useState<ScheduleInterview>(initData);

    const { user } = useAuth();

    useEffect(() => {
        if (employeeId) {
            setEmpId(employeeId);
            setAppId(applicationId);
            if (interviewDates && interviewDates.interviewScheduledAt1) {
                try {
                    let dt = {
                        interviewScheduledAt1: new Date(interviewDates.interviewScheduledAt1),
                        interviewScheduledAt2: new Date(interviewDates.interviewScheduledAt2),
                        interviewScheduledAt3: new Date(interviewDates.interviewScheduledAt3),

                    };
                    setScheduleInt(dt);
                } catch (error) {

                }
            }
        };
    }, [employeeId, applicationId]);

    useEffect(() => {
        if (user && empId) {
            getProfile();
        }
    }, [empId]);

    const getProfile = async () => {

        if (!empId) return;

        try {
            setLoading(true)
            const res_data = await helper.getReq(helper.GET.EMP_PROFILE_DETAILS + empId);
            const { data } = res_data.data;

            if (data) setProfile(data);

            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };



    const getOptionLabel = (val: string | number, opArr: any[]) => {
        const op = opArr.find((x) => x.value == val);

        if (op) return op.label;

        return '';
    };

    const getPOSLabel = (val: string | number) => {
        const pos = positions.find((x) => x.id == val);

        if (pos) return pos.position;

        return '';
    };

    const rejectApplication = async (status: number) => {
        try {
            setLoading2(true);
            const uData = { id: appId, status };
            const res_data = await helper.putReq(helper.PUT.UPDATE_APPLICATION, uData);
            const { data } = res_data.data;

            setLoading2(false);
            if (data) {
                toast.success('Rejected Successfully');
                document.getElementById('updateView')?.click();
                document.getElementById("cancel")?.click();
            }
            else throw new Error("Failed");
        } catch (error) {
            setLoading2(false);
            console.log(error);
            toast.error('Update Failed');
        }
    };

    const handleBack = () => {
        setScheduleInt(initData);
        setIsRequestInt(false);
    };

    const scheduleInterview = async (ev: any) => {
        ev.preventDefault();

        if (scheduleInt.calendarLink && webErr) return toast.error(webErr);

        try {
            setLoading2(true);
            const uData = { id: appId, ...scheduleInt };
            const res_data = await helper.putReq(helper.PUT.SCHEDULE_INTERVIEW, uData);
            const { data } = res_data.data;

            setLoading2(false);
            if (data) {
                toast.success('Scheduled Successfully');
                document.getElementById('updateView')?.click();
                document.getElementById("cancel")?.click();
            }
            else throw new Error("Failed");
        } catch (error) {
            setLoading2(false);
            console.log(error);
            toast.error('Update Failed');
        }
    }

    const handleCheckboxChange = () => {
        setIsLink(!isLink);
        setScheduleInt(scheduleInt);
    };

    const [webErr, setWebErr] = useState<string | null>(null);


    const handleWebsite = (val: string) => {
        const urlRegex = /^(?:(?:https?|ftp):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
        const isValidUrl = urlRegex.test(val);

        if (!isValidUrl) setWebErr('Invalid Calendar URL');
        else setWebErr(null);

        setScheduleInt({ ...scheduleInt, calendarLink: val });
    };

    return (
        <>
            {/* <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Toggle right offcanvas</button> */}

            <div className=" offcanvas offcanvas-end offcanvas-cst-width " tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel"  >
                {/* <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasRightLabel">Offcanvas right</h5>
                            <button type="button" className="btn btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div> */}
                <div className="offcanvas-body">
                    <div className="container-fluid">
                        {
                            loading ? (<h2>Loading...</h2>) : (
                                <>
                                    <div className='float-end'>
                                        {
                                            isRequestInt ? (
                                                <>
                                                    <button className='btn btn-danger' onClick={() => handleBack()}>
                                                        Back
                                                    </button>
                                                    <img src={CloseIcon} className='d-none' id='cancel' data-bs-dismiss="offcanvas" aria-label="Close" />
                                                </>
                                            ) : (
                                                <button className='btn btn-outline btn-close-cst'>
                                                    <img src={CloseIcon} className='img-fluid' id='cancel' data-bs-dismiss="offcanvas" aria-label="Close" />
                                                </button>
                                            )
                                        }
                                    </div>
                                    <div className='d-flex mobile-two-buttons request-for-interview-card-sidbar' style={{ gap: "20px" }}>
                                        <div>
                                            <img src={profile?.imageUrl || DefaultImage} className='img-fluid cst-image-interview-side-model' />
                                        </div>
                                        <div>
                                            <div className='d-flex justify-content-between mobile-two-buttons mb-2' style={{ gap: "16px" }}>
                                                <div>
                                                    <h5
                                                        className="blurred"
                                                        contentEditable={false}
                                                        onMouseDown={(e) => e.preventDefault()}
                                                        onContextMenu={(e) => e.preventDefault()}
                                                        onSelect={(e) => e.preventDefault()}
                                                    >{profile?.name}
                                                    </h5>
                                                </div>
                                                <div className='d-flex flex-wrap' style={{ gap: "16px" }}>
                                                    <button className='btn btn-tag-senior'>{getOptionLabel(profile?.skillAndEmpStatus.seniority || 1, EXPERIENCE_LEVEL)}</button>
                                                    <button className='btn btn-tag-final-interview'>Final Interview</button>
                                                </div>
                                            </div>
                                            <h6>
                                                <span style={{ fontWeight: "400" }}>{getPOSLabel(profile?.skillAndEmpStatus.positionIds[0] || 1)}
                                                    &nbsp;&nbsp;
                                                    <img className="img-fluid" src={MapPinIcon} /> {getOptionLabel(profile?.country || 'US', countries)}
                                                    &nbsp;&nbsp;
                                                    <img className="img-fluid" src={LaunguageIcon} />{getOptionLabel(profile?.skillAndEmpStatus.languages[0].language || 'English', languagesOptions)}<br />
                                                </span>
                                                <div className='d-flex justify-content-between'>
                                                    {
                                                        profile?.skillAndEmpStatus.relativeExp && (
                                                            <span style={{ fontWeight: "500" }}>{profile.skillAndEmpStatus.relativeExp} {profile.skillAndEmpStatus.relativeExp > 1 ? 'years' : 'year'}</span>
                                                        )
                                                    }
                                                    {/* <span>₹1,24,75,000/Yr</span> */}
                                                    <span>{profile?.workingCountry == 'IN' ? '₹' : '$'}{(profile?.employemetStatus?.expectedPASalary || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/Yr</span>
                                                </div>
                                            </h6>
                                            {/* <h6>
                                                <span>₹1,24,75,000/Yr</span>
                                            </h6> */}

                                        </div>

                                    </div>
                                    <hr />
                                    {
                                        !isRequestInt ? (
                                            <>
                                                <div>
                                                    <label className='lable-heading'>Skills</label>
                                                    <div className='d-flex mt-2' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                                        {
                                                            profile?.empSkills.map((sk, index) => (
                                                                // <button className='btn cst-btn-tags' key={index}>
                                                                //     {sk}
                                                                // </button>
                                                                <button className='btn cst-btn-tags' key={index}>
                                                                    {sk.skill}
                                                                    <span className="cst-primary-color">
                                                                        &nbsp;
                                                                        <img src={DotImg} className='img-fluid' />
                                                                        &nbsp;
                                                                        {sk.experience} years
                                                                    </span> </button>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className='row cst-h6-heading-css-main mt-3'>
                                                    <div className='col-sm-9 col-12' >
                                                        <label className='lable-heading' >Experience</label>
                                                        {profile !== undefined && profile.experience.length > 0 && profile.experience.map((exp, index) => (
                                                            <div className='row mt-2' key={index} style={{ paddingRight: "12px" }}>
                                                                <div className='col-md-2 col-12 mb-2'>
                                                                    <img className='img-fluid expirence-image-card-cst' src={DefaultImage} />
                                                                </div>
                                                                <div className='col-md-6 col-12 mb-2 px-3'>
                                                                    <h6>{exp.role}</h6>
                                                                    <label>{exp.company}</label>
                                                                </div>
                                                                <div className='col-md-4 col-12 mb-2'>
                                                                    <h6 className='fst-italic cst-secondary-color'>{`01/${exp.startMonth}/${exp.startYear}`}&nbsp;-&nbsp;{exp.currentlyWorking ? 'Present' : `01/${exp.endMonth}/${exp.endYear}`}</h6>
                                                                    <label className='cst-secondary-color'>{exp.totalExp}</label>
                                                                </div>
                                                                {profile.experience.length - 1 !== index && <hr className='mt-2' />}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className='col-md-3 col-12' style={{ borderLeft: '1px solid rgb(188, 188, 188)' }}>
                                                        <label className='lable-heading' >Candidate’s Resume</label>
                                                        <div
                                                            className='outsourced-interview-result-btn mb-3'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => window.open(`${profile?.employemetStatus.resumeLink}`, '_blank', 'rel=noopener noreferrer')}
                                                        >
                                                            <img src={DownlodIcon} className='img-fluid' />
                                                            Download Resume
                                                        </div>
                                                        <label className='lable-heading' >Assessment</label>
                                                        <div
                                                            className='outsourced-interview-result-btn'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => window.open(`/candidate-gpttest-result/${empId}`, '_blank', 'rel=noopener noreferrer')}
                                                        >
                                                            <img src={DownlodIcon} className='img-fluid' />
                                                            Technical Assessment
                                                        </div>
                                                        {/* <div className='outsourced-interview-result-btn'>
                                                            <img src={DownlodIcon} className='img-fluid' />
                                                            Softskill Assessment Result
                                                        </div>
                                                        <div className='outsourced-interview-result-btn'>
                                                            <img src={DownlodIcon} className='img-fluid' />
                                                            Outsourced Interview Result
                                                        </div> */}

                                                    </div>
                                                </div>
                                                <hr />
                                                <label className='lable-heading' htmlFor="awards">Awards and Acheivements</label>
                                                {/* <div className='d-flex mt-2' style={{ gap: "20px", flexWrap: 'wrap' }}>
                                                    <div>
                                                        <label className='lable-heading' >UX Design Mentor</label>
                                                        <h6>ADPList <span className="cst-primary-color"><img src={DotImg} className='img-fluid' /> Remote</span> </h6>
                                                    </div>
                                                    <div>
                                                        <label className='lable-heading' >UX Design Mentor</label>
                                                        <h6>ADPList <span className="cst-primary-color"><img src={DotImg} className='img-fluid' /> Remote</span> </h6>
                                                    </div>
                                                </div> */}
                                                {/* <label htmlFor="awards" >Education</label>
                                                <div className='d-flex mt-2' style={{ gap: "20px", flexWrap: 'wrap' }}>
                                                    {profile !== undefined && profile.education.length > 0 && profile.education.map((edu, index) => (
                                                        <div key={edu.id}>
                                                            <label>{edu.degree}</label>
                                                            <h6>{edu.school} <span className="cst-primary-color"><img src={DotImg} className='img-fluid' /> {edu.startYear} – {edu.endYear}</span> </h6>
                                                        </div>
                                                    ))}
                                                </div> */}
                                                {/* <hr /> */}
                                                {/* <label htmlFor="awards" >Certification</label> */}
                                                <div className='d-flex mt-2' style={{ gap: "20px", flexWrap: 'wrap' }}>
                                                    {profile !== undefined && profile.certification.length > 0 && profile.certification.map((cert, index) => (
                                                        <div key={cert.id}>
                                                            <label className='lable-heading'>{cert.courseName}</label>
                                                            <h6>{cert.orgName} <span className="cst-primary-color"><img src={DotImg} className='img-fluid' /> {cert.issueDate}</span> </h6>
                                                        </div>
                                                    ))}
                                                </div>

                                                {/* <div className='d-flex justify-content-between flex-wrap mt-4 mb-2 mobile-two-buttons cst-static-buttons-sidemodel-view-profile'> */}
                                                <div className='d-flex justify-content-between flex-wrap mt-4 mb-2 mobile-two-buttons'>
                                                    <button className='btn btn-for-reject' type='button' disabled={loading2} onClick={() => rejectApplication(APPLICATION_STATUS.REJECTED)}  >Reject </button>
                                                    <div className='d-flex flex-wrap' style={{ gap: "16px" }}>
                                                        {/* <button className='btn outsource-interview-cst' type='button' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightTwo" aria-controls="offcanvasRightTwo" > Outsource Interview &nbsp;<img src={InfoVectorImg} data-tooltip-id="my-tooltip-3" /></button> */}
                                                        {/* <button className='btn outsource-interview-cst' type='button' disabled={loading2} onClick={() => setIsRequestInt(true)} >{interviewDates?.interviewScheduledAt1 ? 'Update Interview' : 'Request for interview'} </button> */}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <form name='scheduleForm' onSubmit={scheduleInterview}>
                                                <div>
                                                    <div className='row mb-4'>
                                                        <div className='col-sm-8 col-12'>
                                                            <label htmlFor="calendarlink">Share your calendar link (e.g., Calendly or a similar platform).</label>
                                                            <input
                                                                type='text'
                                                                className='form-control custom-input mt-2'
                                                                placeholder='Enter Your Calendar Link'
                                                                value={scheduleInt.calendarLink}
                                                                onChange={(ev) => handleWebsite(ev?.target.value)}
                                                                disabled={!isLink}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name='ddd'
                                                            id='dddd'
                                                            onChange={(ev) => handleCheckboxChange()}
                                                            checked={!isLink}
                                                        />
                                                        <label className='mx-2'>Alternatively, give us your 3 available date and time for the interview</label>
                                                    </div>

                                                    {!isLink && (
                                                        <>
                                                            <div className='mt-4'>
                                                                <div className='d-flex justify-content-between mobile-flex-col-cst flex-wrap '>
                                                                    <div className='col-sm-6 col-12'>
                                                                        <label htmlFor="date">YYYY-MM-DD</label>
                                                                        <div className="date-picker-input cst-wrapper-100">
                                                                            <DatePicker
                                                                                className='form-control custom-input'
                                                                                showIcon
                                                                                selected={scheduleInt.interviewScheduledAt1}
                                                                                id='interviewScheduledAt1'
                                                                                onChange={(date: any) => setScheduleInt({ ...scheduleInt, interviewScheduledAt1: date })}
                                                                                icon="fa fa-calendar"
                                                                                placeholderText='YYYY-MM-DD'
                                                                                showTimeSelect
                                                                                minDate={new Date()}
                                                                                timeIntervals={15}
                                                                                dateFormat="dd MMM yyyy @ hh:mm a"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='mt-4'>
                                                                <div className='d-flex justify-content-between mobile-flex-col-cst flex-wrap '>
                                                                    <div className='col-sm-6 col-12 mt-2' >
                                                                        <div className="date-picker-input cst-wrapper-100">
                                                                            <DatePicker
                                                                                className='form-control custom-input'
                                                                                showIcon
                                                                                id='interviewScheduledAt2'
                                                                                selected={scheduleInt.interviewScheduledAt2}
                                                                                onChange={(date: any) => setScheduleInt({ ...scheduleInt, interviewScheduledAt2: date })}
                                                                                icon="fa fa-calendar"
                                                                                placeholderText='YYYY-MM-DD'
                                                                                showTimeSelect
                                                                                minDate={new Date()}
                                                                                timeIntervals={15}
                                                                                dateFormat="dd MMM yyyy @ HH:mm a"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='mt-4'>
                                                                <div className='d-flex justify-content-between mobile-flex-col-cst flex-wrap '>
                                                                    <div className='col-sm-6 col-12 mt-2' >
                                                                        <div className="date-picker-input cst-wrapper-100">
                                                                            <DatePicker
                                                                                className='form-control custom-input'
                                                                                showIcon
                                                                                id='interviewScheduledAt3'
                                                                                selected={scheduleInt.interviewScheduledAt3}
                                                                                onChange={(date: any) => setScheduleInt({ ...scheduleInt, interviewScheduledAt3: date })}
                                                                                icon="fa fa-calendar"
                                                                                placeholderText='YYYY-MM-DD'
                                                                                showTimeSelect
                                                                                minDate={new Date()}
                                                                                timeIntervals={15}
                                                                                dateFormat="dd MMM yyyy @ HH:mm a"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </>)}
                                                </div>
                                                <hr className='mt-5' />
                                                <div className='model-footer float-end'>
                                                    <button
                                                        className='btn btn-schdule'
                                                        type='submit'
                                                        disabled={loading2}
                                                    >{loading2 ? 'Scheduling...' : 'Schedule'}</button>
                                                </div>
                                            </form>)
                                    }
                                </>
                            )
                        }

                    </div>
                </div>
            </div >
            {/* <ReactTooltip
                className='save-engineering-cst'
                id="my-tooltip-3"
                place="bottom"
                render={() => (
                    <div>
                        Save 90% of engineering &<br /> recruitment bandwidth, outsource <br />candidate interviews to us
                    </div>
                )}
            />

            {
                applicationId !== undefined && <RequestOutSourceInterview
                    profile={profile}
                    positions={positions}
                    posData={posData}
                    applicationId={applicationId}
                /> */}
            {/* } */}

        </>
    )
}

export default RequestForInterviewModel