import CoreSkillsIcon from '../../Assets/Icons/CoreSkilssIcon.png'
import AdditionalSkillsIcon from '../../Assets/Icons/AdditionalSkillsIcon.png'
import { useEffect, useState } from 'react';
import { Skill, skData } from '../../types';
import Helper from '../../utils/helper';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import NotificationComponent from '../../Components/NotificationComponent'
import { Tooltip as ReactTooltip } from "react-tooltip";
import { SKILL_TYPES } from '../../utils/constant';
import EmpNotificationComponent from '../../Components/EmpNotificationComponent';

const EmployeeGptVetting = () => {

  const [skillsData, setSkillsData] = useState<skData[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const helper = Helper.getInstance();
  const { user, getDetails } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    getDetails(false);
  }, []);

  useEffect(() => {
    if (user) {
      getSkData();
    }
  }, [user]);

  const getSkData = async () => {
    try {
      setLoading(true);
      const res_data = await helper.getReq(helper.GET.SK_DATA);
      const { data } = res_data.data;

       if (data) setSkillsData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const startTest = (skill: Skill, type: number) => {
    navigate(`/candidate-gpttest-start`, { state: { skill: skill.skill, exp: skill.experience, type } });
  };

  return (
    <>
      <div id="content" className='pt-5'>
        <div className='top-navigation mb-5'>
          <EmpNotificationComponent />
          <h2>Gen AI Vetting</h2>
          <p>Complete skill assessments to get matched with right jobs.</p>
        </div>
        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='card card-cst-GPT-vetting p-3'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center' style={{ gap: "12px" }}>
                  <img src={CoreSkillsIcon} className='img-fluid' data-tooltip-id="my-tooltip-10" />
                  <h5>Core Skills</h5>
                </div>
                <div>
                  <span>Vetted Skills</span>
                  <h5>{skillsData?.filter((sk) => sk.skillType == SKILL_TYPES.MAIN && sk.assesmentResult !== undefined)?.length || 0}/{skillsData?.filter((sk) => (sk.skillType == SKILL_TYPES.MAIN)).length || 0}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-12'>
            <div className='card card-cst-GPT-vetting p-3'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center' style={{ gap: "12px" }}>
                  <img src={AdditionalSkillsIcon} className='img-fluid' data-tooltip-id="my-tooltip-11" />
                  <h5>Additional Skills</h5>
                </div>
                <div>
                  <span>Vetted Skills</span>
                  <h5>{skillsData?.filter((sk) => sk.skillType == SKILL_TYPES.ADDITIONAL && sk.assesmentResult !== undefined)?.length || 0}/{skillsData?.filter((sk) => (sk.skillType == SKILL_TYPES.ADDITIONAL)).length || 0}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card card-cst-GPT-vetting p-3 mt-4'>
          <h4>Core Skills</h4>
          <div className='row align-items-center mb-2'>
            <div className='col-sm-5 col-12 mt-2 mt-2'>
              <label>Skill</label>
            </div>
            <div className='col-sm-5 col-12 mt-2'>
              <label>Years of Experience</label>
            </div>


          </div>
          {skillsData?.map((sk) => {
            if (sk.skillType == SKILL_TYPES.MAIN) {
              return (
                <div className='row align-items-center mb-3' key={sk.skill}>
                  <div className='col-sm-5 col-12 mt-2 mt-2'>
                    <input
                      type='text'
                      className='form-control custom-input'
                      value={sk.skill}
                      readOnly
                      disabled
                    />
                  </div>
                  <div className='col-sm-5 col-12 mt-2'>
                    <input
                      type='text'
                      className='form-control custom-input'
                      value={sk.experience}
                      readOnly
                      disabled
                    />
                  </div>
                  {sk.assesmentResult ? (
                    <div className='col-sm-2 col-12 mt-2'>
                      <label>{sk.assesmentResult.result}/10</label>
                    </div>
                  ) : (
                    <div className='col-sm-2 col-12 mt-2'>
                      <button
                        className='btn start-test-button col-12'
                        onClick={() => startTest(sk, 1)}
                      >Start Test</button>
                    </div>
                  )}
                </div>
              )
            }
          })}
          {/* <div className='row align-items-center'>
            <div className='col-sm-5 col-12 mt-2 mt-2'>
              <label>Skill</label>
              <input type='text' className='form-control custom-input' placeholder='Visual designer' />
            </div>
            <div className='col-sm-5 col-12 mt-2'>
              <label>Years of Experience</label>
              <input type='text' className='form-control custom-input' placeholder='2' />
            </div>
            <div className='col-sm-2 col-12 mt-2'>
              <button className='btn start-test-button col-12'>Start Test</button>
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-sm-5 col-12 mt-2 mt-2'>
              <input type='text' className='form-control custom-input' placeholder='Visual designer' />
            </div>
            <div className='col-sm-5 col-12 mt-2 mt-2'>
              <input type='text' className='form-control custom-input' placeholder='3' />
            </div>
            <div className='col-sm-2 col-12 mt-2 '>
              <button className='btn start-test-button col-12'>Start Test</button>
            </div>
          </div>
          <div className='row align-items-center mt-2'>
            <div className='col-sm-5 col-12 mt-2 mt-2'>
              <input type='text' className='form-control custom-input' placeholder='Visual designer' />
            </div>
            <div className='col-sm-5 col-12 mt-2 mt-2'>
              <input type='text' className='form-control custom-input' placeholder='5' />
            </div>
            <div className='col-sm-2 col-12 mt-2 '>
              <button className='btn start-test-button col-12'>Start Test</button>
            </div>
          </div> */}
          <h6
            className='mt-2 cst-primary-color fw-bold'
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/candidate-my-profile', { replace: true })}
          >+ Add more Skills</h6>
        </div>


        <div className='card card-cst-GPT-vetting p-3 mt-4'>
          <h4>Additional Skills</h4>
          <div className='row align-items-center mb-2'>
            <div className='col-sm-5 col-12 mt-2 mt-2'>
              <label>Skill</label>
            </div>
            <div className='col-sm-5 col-12 mt-2'>
              <label>Years of Experience</label>
            </div>


          </div>
          {skillsData?.map((sk) => {
            if (sk.skillType == SKILL_TYPES.ADDITIONAL) {
              return (
                <div className='row align-items-center mb-3' key={sk.skill}>
                  <div className='col-sm-5 col-12 mt-2 mt-2'>
                    <input
                      type='text'
                      className='form-control custom-input'
                      value={sk.skill}
                      readOnly
                      disabled
                    />
                  </div>
                  <div className='col-sm-5 col-12 mt-2'>
                    <input
                      type='text'
                      className='form-control custom-input'
                      value={sk.experience}
                      readOnly
                      disabled
                    />
                  </div>
                  {sk.assesmentResult ? (
                    <div className='col-sm-2 col-12 mt-2'>
                      <label>{sk.assesmentResult.result}/10</label>
                    </div>
                  ) : (
                    <div className='col-sm-2 col-12 mt-2'>
                      <button
                        className='btn start-test-button col-12'
                        onClick={() => startTest(sk, 2)}
                      >Start Test</button>
                    </div>
                  )}
                </div>
              )
            }
          })}

          {/* <div className='row align-items-center'>
            <div className='col-sm-5 col-12 mt-2 mt-2'>
              <label>Skill</label>
              <input type='text' className='form-control custom-input' placeholder='Visual designer' />
            </div>
            <div className='col-sm-5 col-12 mt-2 mt-2'>
              <label>Being Tested as</label>
              <input type='text' className='form-control custom-input' placeholder='2' />
            </div>
            <div className='col-sm-2 col-12 mt-2 mt-3'>
              <button className='btn start-test-button col-12'>Start Test</button>
            </div>
          </div>
          <div className='row align-items-center mt-2'>
            <div className='col-sm-5 col-12 mt-2 mt-2'>
              <input type='text' className='form-control custom-input' placeholder='Visual designer' />
            </div>
            <div className='col-sm-5 col-12 mt-2 mt-2'>
              <input type='text' className='form-control custom-input' placeholder='3' />
            </div>
            <div className='col-sm-2 col-12 mt-2 '>
              <button className='btn start-test-button col-12'>Start Test</button>
            </div>
          </div>
          <div className='row align-items-center mt-2'>
            <div className='col-sm-5 col-12 mt-2 mt-2'>
              <input type='text' className='form-control custom-input' placeholder='Visual designer' />
            </div>
            <div className='col-sm-5 col-12 mt-2 mt-2'>
              <input type='text' className='form-control custom-input' placeholder='5' />
            </div>
            <div className='col-sm-2 col-12 mt-2 '>
              <button className='btn start-test-button col-12'>Start Test</button>
            </div>
          </div>
          <h6 className='mt-2 cst-primary-color fw-bold'>+ Add 2 more Skill</h6> */}
          <h6
            className='mt-2 cst-primary-color fw-bold'
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/candidate-my-profile', { replace: true })}
          >+ Add more Skills</h6>
        </div>
      </div>
      <ReactTooltip
        className='cst-bg-blue-tooltip'
        id="my-tooltip-10"
        place="bottom"
        render={(props) => (
          <div>
            Save 90% of engineering &<br /> recruitment bandwidth, outsource<br /> candidate interviews to us
          </div>
        )}
      />
      <ReactTooltip
        className='cst-bg-blue-tooltip'
        id="my-tooltip-11"
        place="bottom"
        render={(props) => (
          <div>
            Save 90% of engineering &<br /> recruitment bandwidth, outsource<br /> candidate interviews to us
          </div>
        )}
      />
    </>
  )
}

export default EmployeeGptVetting