import { useEffect, useState } from 'react'
import CulculatorIcon from '../../Assets/Icons/Group23.svg'
import UserIcon from '../../Assets/Icons/Group24.svg'
import WorkOpportunityIcon from '../../Assets/Icons/Image20240404174339.png'
import CompanyInfoIcon from '../../Assets/Icons/Group25.png'
import QuestionMarkIcon from '../../Assets/Icons/Group26.png'
import DashboardIcon from '../../Assets/Icons/Group21.svg'
import SpocIcon from '../../Assets/Icons/Group27.svg'
import ThreeDotMenu from '../../Assets/Icons/threeDotMenu.svg'
import useAuth from '../../hooks/useAuth'
// import DefaultImage from '../../Assets/Icons/default.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import CrossIcon from '../../Assets/Icons/CrossVector.svg'
import MobileOwowLogo from '../../Assets/Icons/owowMobileLogo.svg'
import NotificationMobileIcon from '../../Assets/Icons/mobileNotification.svg'
import ToggleIcon from '../../Assets/Icons/toggleIcon.svg'
import ProfileIcon from '../../Assets/Icons/myprofileIcon.png';
import Logo_Horizontal_purpleImage from '../../Assets/Icons/Logo_Horizontal_purple.png'
import Logo_Horizontal_WhiteImage from '../../Assets/Icons/Logo_Horizontal_White.png'
import GenAIVettigIcon from '../../Assets/Icons/Image20240404225708.png'
// import NotificationComponentMobile from '../NotificationComponentMobile'
import DefaultImage from '../../Assets/Icons/default.png';
import EmpNotificationComponentMobile from '../EmpNotificationComponentMobile'

const EmpSideBar = () => {
    const { user, logout } = useAuth();
    const location = useLocation();
    // console.log(location.pathname, "location")
    const navigate = useNavigate()
    const [toggle, settoggle] = useState(false);
    const [smScreen, setSmScreen] = useState(false);

    const handleSidbarToggleButton = () => {
        settoggle(!toggle)
    }



    const handleDashbord = (e: any) => {
        e.preventDefault();
        navigate("/candidate-dashboard")
        if (window.innerWidth <= 1024) {
            settoggle(!toggle);
        }
    }

    const handleMyProfile = (e: any) => {
        e.preventDefault();
        navigate("/candidate-my-profile")
        if (window.innerWidth <= 1024) {
            settoggle(!toggle);
        }
    }

    const handleSavedJobs = (e: any) => {
        e.preventDefault();
        navigate("/candidate-saved-jobs")
        if (window.innerWidth <= 1024) {
            settoggle(!toggle);
        }
    }

    const handleAllJobs = (e: any) => {
        e.preventDefault();
        navigate("/candidate-all-jobs")
        if (window.innerWidth <= 1024) {
            settoggle(!toggle);
        }
    }

    const handleMyApplications = (e: any) => {
        e.preventDefault();
        navigate("/candidate-my-applications")
        if (window.innerWidth <= 1024) {
            settoggle(!toggle);
        }
    }

    const handleHelp = (e: any) => {
        e.preventDefault();
        navigate("/candidate-help")
        if (window.innerWidth <= 1024) {
            settoggle(!toggle);
        }
    }

    const handleSpoc = (e: any) => {
        e.preventDefault();
        navigate("/candidate-spoc")
        if (window.innerWidth <= 1024) {
            settoggle(!toggle);
        }
    }
    const handleMyAccount = (e: any) => {
        e.preventDefault();
        navigate("/candidate-my-account")
        if (window.innerWidth <= 1024) {
            settoggle(!toggle);
        }
    }
    const handleGptVetting = (e: any) => {
        e.preventDefault();
        // navigate("/candidate-gpt-vetting")
        navigate("/candidate-gpt-vetting-page")
        if (window.innerWidth <= 1024) {
            settoggle(!toggle);
        }
    }



    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1025) {
                settoggle(true); // Set 'toggle' to 'true' for mobile screens
                setSmScreen(true);
            } else {
                settoggle(false);
                setSmScreen(false);
            }
        };
        // Add an event listener for window resize
        window.addEventListener('resize', handleResize);
        // Initial check when the component mounts
        handleResize();
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const DropdownButtonForLogout = () => {
        return <>
            <img src={user?.imageUrl || DefaultImage} className='img-fluid' style={{ width: '40px', height: '40px', cursor: 'pointer' }} />&nbsp;My Account &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img src={ThreeDotMenu} className='img-fluid dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" />
            <ul className="dropdown-menu">
                <li><a className="p-3 " onClick={() => logout(true)} style={{ background: "white", color: "red", fontWeight: "700" }}>Logout</a></li>
            </ul>
        </>
    }

    return (
        <div className="container-flex ">
            {smScreen && (
                <div className='row' style={{ backgroundColor: '#FBF9FF' }}>
                    <div className="sidebar-header px-4 pt-5 pb-4">
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <img onClick={handleDashbord} src={Logo_Horizontal_purpleImage} alt='logo' className='img-fluid mobile-logo' />
                            </div>
                            <div className='mobile-logo' >
                                <div className='d-flex' style={{ gap: "26px" }}>
                                    {/* <img src={NotificationMobileIcon} className='img-fluid cst-hide-fot-tab' /> */}
                                    <EmpNotificationComponentMobile/>
                                    <img src={toggle ? CrossIcon : ToggleIcon} className='img-fluid' onClick={handleSidbarToggleButton} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            <div className='wrapper d-flex align-items-stretch'>
                <nav id="sidebar" className={`${toggle ? "active" : 'hidden'}`}>
                    <div className='p-5 desktop-logo'>
                        <img onClick={handleDashbord} src={Logo_Horizontal_purpleImage} alt='logo' className='img-fluid' />
                    </div>

                    <ul className="list-unstyled components">
                        <li className={`${location.pathname === '/candidate-dashboard' ? "active" : ""}`} onClick={handleDashbord} >
                            <a href="#dasktop"><img src={DashboardIcon} />&nbsp; Dashboard</a>
                        </li>
                        {/* This is For Employee */}
                        <li className={`${location.pathname === "/candidate-my-profile" ? "active" : ""}`} onClick={handleMyProfile}>
                            <a href="#"><img src={ProfileIcon} className="my-profile-cst" />&nbsp; My Profile</a>
                        </li>
                        {/* <li className={`activ`}>
                            <a href="#companyInfoSubmenu" data-bs-toggle="collapse" data-toggle="collapse" data-bs-target="#companyInfoSubmenu" aria-expanded="false" className="dropdown-toggle">
                                <img src={CompanyInfoIcon} />&nbsp; Company Info
                            </a>
                            <ul className="collapse list-unstyled mt-1" id="companyInfoSubmenu">
                                <li >
                                    <a href="#" onClick={() => (handleNavigate("/employer-company-details"))}>General information</a>
                                </li>
                                <li>
                                    <a href="#">Payments and refunds</a>
                                </li>
                                <li>
                                    <a href="#">Billing and invoices</a>
                                </li>
                            </ul>
                        </li> */}
                        <li className={`${location.pathname === "/candidate-all-jobs" || location.pathname === "/candidate-my-applications" || location.pathname === "/candidate-saved-jobs" ? "active" : ""}`} >
                            <a href="#workOprtinitySubmenu" data-bs-toggle="collapse" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><img src={WorkOpportunityIcon} className='img-fluid' />&nbsp; Work Opportunity</a>
                            <ul className="collapse list-unstyled mt-1" id="workOprtinitySubmenu">
                                <li className={`${location.pathname === "/candidate-all-jobs" ? "active" : ""}`} >
                                    <a href="#" onClick={handleAllJobs}>All Jobs</a>
                                </li>
                                <li className={`${location.pathname === "/candidate-my-applications" ? "active" : ""}`}>
                                    <a href="#" onClick={handleMyApplications}>My Applications</a>
                                </li>
                                <li className={`${location.pathname === "/candidate-saved-jobs" ? "active" : ""}`}>
                                    <a href="#" onClick={handleSavedJobs}>Saved Jobs</a>
                                </li>
                            </ul>
                        </li>
                        <li className={`${location.pathname === "/candidate-gpt-vetting-page" ? "active" : ""}`} onClick={handleGptVetting}>
                            <a href=""><img src={GenAIVettigIcon} className='gen-ai-vetting-cst'/>&nbsp; Gen AI Vetting</a>
                        </li>

                        <li className={`${location.pathname === "/candidate-help" ? "active" : ""}`} onClick={handleHelp}>
                            <a href="#"><img src={QuestionMarkIcon} />&nbsp; Help</a>
                        </li>
                        <li className={`${location.pathname === "/candidate-spoc" ? "active" : ""}`} onClick={handleSpoc}>
                            <a href="#"><img src={SpocIcon} />&nbsp; Your SPOC</a>
                        </li>
                        <li className={`${location.pathname === "/candidate-my-account" ? "active" : ""}`} onClick={handleMyAccount}>
                            <a href="#"><img src={SpocIcon} />&nbsp; My Account</a>
                        </li>

                        <li onClick={() => logout()} style={{ marginTop: "100px" }}>
                            <a href="#"> <img src={user?.imageUrl || DefaultImage} className='img-fluid btn-add-dropdwon-cst dropdown-toggle' style={{ width: '50px', height: '50px', cursor: 'pointer', borderRadius: "50%" }} />&nbsp; Logout</a>
                        </li>

                        {/* <li className={`activ`} style={{ marginTop: "100px" }}>
                            <div className="dropdown">
                                <a href="#" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"  >
                                    <img src={user?.imageUrl || DefaultImage} className='img-fluid btn-add-dropdwon-cst dropdown-toggle' style={{ width: '40px', height: '40px', cursor: 'pointer' }} />&nbsp;My Account &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <img src={ThreeDotMenu} className='img-fluid ' />
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><span className="dropdown-item" onClick={() => logout()}>Logout</span></li>
                                    </ul>
                                </a>
                            </div>
                        </li> */}
                    </ul>
                </nav>

                <Outlet />
            </div>

        </div>
    )
}

export default EmpSideBar