export interface UserEmailPass {
    email: string;
    password: string;
};

export interface UserPersonalDetails {
    name: string;
    jobTitle: string;
    dob: string;
    phone: string;
    email?: string;
};

export interface User {
    id: number;
    email: string;
    name?: string;
    jobTitle?: string;
    dob?: string;
    phone?: string;
    countryCode?: string;
    password?: string;
    emailVerificationToken?: string;
    isEmailVerified?: string;
    phoneVerificationToken?: string;
    isPhoneVerified?: string;
    isSocialLogin?: string;
    isGuideGiven?: boolean;
    socailToken?: string;
    gender?: string;
    linkedinUrl?: string;
    isCollaborator?: boolean;
    accessToken?: string;
    imageUrl?: string;
    addedById?: number;
    SPOCName?: string;
    SPOCIamgeURL?: string;
    SPOCRole?: string;
    SPOCPhone?: string;
    SPOCWpPhone?: string;
    SPOCEmail?: string;
    jobsBookmarked?: number[];
    country?: string;
    workingCountry?: string;
    isAllSkillsVetted?: boolean;
    regCompPerc?: number;
};

export interface CompanyDetails {
    id?: number;
    customerId: number;
    country: string;
    currency: string;
    name: string;
    type: string;
    registrationNumber: string;
    VAT?: string;
    EIN?: string;
    taxId?: string;
    industry?: string;
    employeeSize?: string;
    website?: string;
    linkedInUrl?: string;
    imageUrl?: string;
};

export interface CompanyAddressDetails {
    id?: number;
    companyId: number;
    country: string;
    state: string;
    city: string;
    address1: string;
    address2: string;
    zipCode: string;
    billingCountry?: string;
    billingState?: string;
    billingCity?: string;
    billingAddress1?: string;
    billingAddress2?: string;
    billingZipCode?: string;
};

export interface initialLoginContextProps {
    isLoggedIn: boolean;
    isInitialized?: boolean;
    communityId?: string;
    memType?: number;
    user?: any | null | undefined;
};

export type KeyedObject = {
    [key: string]: string | number | KeyedObject | any;
};

export type JWTContextType = {
    isLoggedIn: boolean;
    isInitialized?: boolean;
    user?: User | null;
    register: (values: UserEmailPass, isEmp?: boolean) => Promise<any>;
    login: (email: string, password: string, isEmp?: boolean) => Promise<void>;
    logout: (isEmp?: boolean) => void;
    socialLogin: (token: string, loginType: number, isEmp?: boolean) => void;
    tokenLogin: (token: string, isEmp?: boolean) => any;
    getDetails: (isRedirect?: boolean) => void;
}

export interface CompanyFirstPageProps {
    handleNextButton: (view: boolean) => void;
    company: CompanyDetails | null;
}

export interface CompanySecondPageProps {
    handlebackButton: (view: boolean) => void;
    setCompany: (data: any) => void;
    data: CompanyDetails | null;
}

export interface SideBarProps {
    customer: User;
}

export interface cusIdProps {
    customerId: number | undefined;
}

export interface CollaboratorDetails {
    id?: number;
    addedById: number;
    name: string;
    email: string;
    phone: string;
    role: string;
    isActive: boolean;
    createdAt?: string;
};

export interface PasswordData {
    customerId: number;
    oldPassword: string;
    newPassword: string;
    retypeNewPassword: string;
};

export interface ResetPasswordType {
    customerId: number;
    email: string;
    password: string;
    confirmPassword: string;
};

export interface TicketDetails {
    id?: number;
    customerId?: number;
    title: string;
    description: string;
    status?: number;
    imageUrl?: string;
    resolvedBy?: number;
    createdAt?: string;
};

export interface ObjectMap {
    [key: string | number]: any | undefined
};

export const imageAllowedFileTypes = `image/gif image/png, image/jpeg, image/x-png`;

export interface PositionDetails {
    id: number;
    position: string;
    salaryId: number;
    createdAt: string;
    salary?: number;
    salaryType?: number;
    seniority?: number;
    positionType?: number;
    description?: string;
    skills?: string[];
    optionalSkills?: string[];
    hideTitle?: Boolean;
    descLength?: number;
    numberOfPosition?: number;
    positionCount?: number;

    customerId?: number;
};

export interface OptionType {
    label: string | number;
    value: string | number;
};

export interface OptionType2 {
    label: string;
    value: number;
};

export interface LanguageSelected {
    language: string;
    fluency: string;
};

export interface PosReqOtherDetails {
    languagesData: LanguageSelected[];
    timezone: string;
    officePolicy: number;
    startDate: string;
    perks: number[];
    isPostConsent: boolean;
    imageUrl?: string;
};

export interface CostCalcOptions {
    position?: number;
    seniority?: number;
    perks?: number[];
    country: string;
};

export interface Salary {
    id: number;
    juniorLower: number;
    juniorUpper: number;
    juniorRange: number;
    midLower: number;
    midUpper: number;
    midRange: number;
    seniorLower: number;
    seniorUpper: number;
    seniorRange: number;
    staffLower: number;
    staffUpper: number;
    staffRange: number;
    unit: string
    currency: string
    multiplier: number;
}

export interface SalaryDetails {
    id: number;
    position: string;
    salaryId: number;

    salary: Salary;
};

export interface PerkModelProps {
    setPosReqOtherData: Function;
};

export interface CostWidthAndData {
    refCountrySalary: number;
    refCountryBenifit: number;
    refCountryTotal: number;
    altCountrySalary: number;
    altCountryBenifit: number;
    altCountryTotal: number;
    refCountrySalaryPerc: number;
    refCountryBenifitPerc: number;
    altCountrySalaryPerc: number;
    altCountryBenifitPerc: number;
    savings: number;
    savedPerc: number;
    altUlWidth: number;
};

export interface CostCalcReturnData {
    upper: number;
    lower: number;
    range: number;
    multiplier: number;
}

export interface SkillsAndExpDetails {
    id?: number;
    employeeId: number;
    positionIds: number[];
    // skills: any[];
    // additionalSkills: any[];
    seniority: number;
    relativeExp?: number;
    officePolicys: number[];
    timezone: string;
    languages: any[];
};

export interface SkillsAndExpDetails2 {
    id?: number;
    employeeId: number;
    positionIds: number[];
    skills: any[];
    additionalSkills: any[];
    seniority: number;
    relativeExp?: number;
    officePolicys: number[];
    timezone: string;
    languages: any[];
};


export interface EmpStatusDetails {
    id?: number;
    employeeId: number | null;
    status: number | null;
    noticePeriod: number | null;
    positionType: number | null;
    currentPASalary: number | null;
    expectedPASalary: number | null;
    resumeLink: string;
};

export interface ProfileDetails {
    name: string;
    skAndExp: SkillsAndExpDetails;
    empStatus: EmpStatusDetails;
    empSkills: Skill[];
    experience: Experience[];
    education: Education[];
    certification: Certification[];
};

export interface Employee {
    id?: number;
    name: string;
    gender: string;
    imageUrl?: string;
    country: string;
};

export interface EmployeeProfileModalProps {
    seniority: number;
    languages: any[];
};

export interface AddSkillsModalProps {
    skills: Skill[];
    skillsDB: OptionType[];
};

export interface Experience {
    id?: number;
    employeeId: number;
    company: string;
    role: string;
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
    currentlyWorking: boolean;
    totalExp: string;
};

export interface Education {
    id?: number;
    employeeId: number;
    degree: string;
    school: string;
    startYear: string;
    endYear: string;
};

export interface Certification {
    id?: number;
    employeeId: number;
    courseName: string;
    orgName: string;
    issueDate: string;
    expirationDate?: string;
    credentialId?: string;
    credentialUrl?: string;
};

export interface ExperienceProps {
    experience: Experience | null;
};

export interface EducationProps {
    education: Education | null;
};

export interface CertificationProps {
    certification: Certification | null;
};

export interface JobPreferences {
    officePolicys: number[];
    timezone: string;
    positionType: number | null;
    noticePeriod: number | null;
    currentPositionId?: any;
    positionIds: number[];
    currentPASalary: number | null;
    expectedPASalary: number | null;
};

export interface PosReqData {
    id: number;
    customerId: number;
    positionId: number;
    numberOfPosition: number;
    seniority: number;
    skills: string[];
    optionalSkills: string[];
    positionType: number;
    description: string;
    status: number;
    languages: any[]
    timezone: string;
    officePolicy: number;
    startDate: string;
    perks: number[];
    company: CompanyDetails;
    companyAddres: CompanyAddressDetails;

    noOfApply?: number;
    noOfHired?: number;
    noOfShortlisted?: number;
    noOfRejected?: number;
    noOfUnseen?: number;

    createdAt: string;
    isBookMarked?: boolean;
    isApplied?: boolean;
};

export interface PosReqData2 {
    id: number;
    positionId: number;
    seniority: number;
    officePolicy: number;
    timezone: string;
    company: {
        id: number;
        name: string;
        imageUrl?: string;
        country: string;
    },
}

export interface ApplyJobProps {
    positions: OptionType[];
    user: User;
    posId: number;
    setApplied: Function;
};

export interface ApplPosReqData {
    id: number;
    positionId: number;
    seniority: number;
    positionType: number;
    timezone: string;
    officePolicy: number;
    company: {
        name: string;
        imageUrl: string;
        country: string;
    }
    JobApplication: JobApl[];
    createdAt: string;
}

interface JobApl {
    status: number;
    interviewScheduledAt1?: string;
    calendarLink?: string;
}

export interface jobSearch {
    positionIds: number[];
    skills: string[];
    optionalSkills: string[];
    seniority?: number;
    officePolicy: number[];
    location: string[];
    sortBy?: number;
    showBookmarked?: boolean;
    search?: string;
    isShowMatchingOnly: boolean;
};

export interface AllRequirementsCardProps {
    setActiveTab: Function;
    setCurrentPosReq: Function;
    positions: PositionDetails[];
};

interface skAndExp2 {
    positionIds: number[];
    // skills: any[];
    relativeExp: number;
    languages: any[];
};

interface employemetStatus2 {
    id: number;
    expectedPASalary: number;
    resumeLink?: string;
};

interface User2 {
    id: number;
    name: string;
    country: string;
    workingCountry: string;
    imageUrl?: string;
    empSkills: Skill[];
    skillAndEmpStatus: skAndExp2;
    employemetStatus: employemetStatus2;
};

export interface ApplicantData {
    id: number;
    status: number;
    interviewScheduledAt1: Date | null;
    interviewScheduledAt2: Date | null;
    interviewScheduledAt3: Date | null;
    employee: User2;
};

export interface ViewJobDetailsProps {
    positions: PositionDetails[];
    posReqId: number;
};

export interface RequestForInterviewModelProps {
    positions: PositionDetails[];
    employeeId: number;
    applicationId: number;
    interviewDates: any;
    posData?: PosDetails;
};

export interface ViewCandidProfileProps {
    positions: PositionDetails[];
    employeeId: number;
};

export interface EmpProfileDetails {
    id: number;
    email: string;
    name?: string;
    imageUrl?: string;
    country?: string;
    workingCountry?: string;
    skillAndEmpStatus: SkillsAndExpDetails;
    empSkills: Skill[];
    employemetStatus: employemetStatus2;
    experience: Experience[];
    education: Education[];
    certification: Certification[];
};

export interface ScheduleInterview {
    interviewScheduledAt1: Date | null;
    interviewScheduledAt2: Date | null;
    interviewScheduledAt3: Date | null;
    calendarLink?: string;
};

export interface RetentionBonus {
    compensationFrequency: string;
    effectiveDate: string;
    type: string;
    amount?: number;
};

export interface GratuityAllowance {
    paymentType: string;
    amount?: number;
};

export interface SigningBonus {
    effectiveDate: string;
    amount?: number;
};

export interface Talent {
    id?: number;
    employeeId: number;
    positionRequestId: number;
    typeOfWorker?: string;
    monthlyRate?: number;
    invoiceCycle?: string;
    officialEmailId?: string;
    officialEmployeeId?: string;
    annualCompensation?: number;
    retentionBonus?: RetentionBonus;
    gratuityAllowance?: GratuityAllowance;
    signingBonus?: SigningBonus;
    joiningDate?: string;
    probationPeriod?: number;
    timeOffType?: number;
    timeOffValue?: number;
    empTermType?: number;
    contractEndingDate?: string;
    noticePeriod?: number;
    perks: number[];
    paymentId?: number;
    contractAgreementId?: number;
    imageUrl?: string;
};

export interface TalentDetails {
    id: number;
    joiningDate: Date | null;
    positionRequestId: number;
    applicationId: number;
    employee: {
        id: number;
        name: string;
        imageUrl: string;
        country: string;
    };
    position: {
        id: number;
        positionId: number;
        positionType: number;
    };
    paymentId?: number;
    contractAgreementId?: number;
}

export interface TalentProfile {
    id: number;
    positionRequestId: number;
    employee: {
        id: number;
        name: string;
        imageUrl: string;
        country: string;
        workingCountry: string;
        skillAndEmpStatus: {
            positionIds: number[];
            relativeExp: number;
        };
    };
    position: {
        id: number;
        positionId: number;
        perks: number[];
        company: { country: string }
    };
};

export interface BonusProps {
    setTalent: Function;
};

export interface PositionsProject {
    id: number;
    position: string;
};

interface PosReqProjected {
    id: number;
    positionId: number;
    numberOfPosition: number;
    noOfApply: number;
    noOfHired: number;
    noOfShortlisted: number;
    noOfRejected: number;
    noOfUnseen: number;
    createdAt: string;
};

export interface EmployerDash {
    allTalents: number;
    jobPosted: number;
    vacancies: number;
    hired: number;
    jobs: PosReqProjected[];
    positions: PositionsProject[];
};

export interface PosReqProjected2 {
    id: number;
    positionId: number;
    seniority: number;
    officePolicy: number;
    company: {
        id: number;
        country: string;
        name: string;
        imageUrl: string;
    }
}

export interface EmployeeDash {
    allJobsCount: number;
    matchingJobsCount: number;
    appliedJobsCount: number;
    interviewJobsCount: number;
    newJobs: PosReqProjected2[];
    appliedJobs: PosReqProjected2[];
    positions: PositionsProject[];
};

export interface PasswordValidation {
    hasNumber: boolean;
    hasSpecialChar: boolean;
    hasUppercase: boolean;
    hasLowercase: boolean;
    isMinLength: boolean;
};

export interface FilterJobsProps {
    positions: OptionType[];
    applyFilter: Function;
    searchM: jobSearch;
    skillsDB: OptionType[];
}

export interface OutSouceData {
    id?: number;
    companyName: string;
    description: string;
    pricing: number;
    createdAt?: string;
    imageUrl?: string;
    clientLogoUrls?: string[];
};

export interface OutSourceProps {
    profile: EmpProfileDetails | undefined;
    positions: PositionDetails[];
    posData?: PosDetails;
    applicationId: number;
};

export interface PosDetails {
    id: number;
    positionId: number;
    position: {
        position: string;
    };
    perks: number[];
    skills: string[];
    optionalSkills: string[];
    description: string;
    seniority: number;
    positionType: number;
    status: number;
    languages: any[]
    timezone: string;
    officePolicy: number;
    companyAddres: {
        country: string;
    }
};

export interface Skill {
    id: number;
    skill: string;
    experience: number;
    skillType: number;
    assesmentResult?: {
        id: number;
        result: number;
        createdAt?: string;
    };
};

export interface skData {
    id: number;
    skill: string;
    experience: number;
    skillType: number;
    assesmentResult?: {
        id: number;
        result: number;
    };
};

export interface skData2 {
    id: number;
    skill: string;
    experience: number;
    skillType: number;
    assesmentResult?: {
        id: number;
        result: number;
        createdAt: string;
    };
};

export interface Employee3 {
    id: number;
    name: string;
    jobTitle: string;
}

export interface TestData {
    que: string;
    opt: string[];
    ans: string;
    givenAns?: string;
}

export interface NotificationData {
    id: number;
    message: string;
    heading: string;
    dataId?: number;
    dataImageURL?: string;
    navigatePath?: string;
    createdAt: string;
    updatedAt: string;
}

export interface SkillData {
    id: number;
    skill: string;
}

export interface ResultObj {
    feedback: string;
    score: string;
}

export interface AssesmentResultData {
    questions: string[];
    answers: string[];
    results: string[];
    overAllPerformance: string[];
}

export interface GPTQuestion {
    text: string;
    audio_url: string;
}