import React, { useEffect, useState } from 'react'
import BagIcon from '../../Assets/Icons/BagsIcon.png'
import MatchingJobsIcon from '../../Assets/Icons/matchingJobsIcon.png'
import JobsAppliedIcon from '../../Assets/Icons/JobsAppliedIcon.png'
import InteviewSchduleIcon from '../../Assets/Icons/InteviewSchduleIcon.png'
import LocationIcon from '../../Assets/Icons/LocationIcon.svg';
import WorkSuitcaseIcon from '../../Assets/Icons/material-symbols_work-outline.svg';
import { useLocation, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { EmployeeDash, PosReqProjected2, PositionsProject } from '../../types'
import Helper from '../../utils/helper'
import DefaultImage from '../../Assets/Images/Image20240129183323.png';
import { EXPERIENCE_LEVEL, OFFICE_POLICY } from '../../utils/constant'
import NotificationComponent from '../../Components/NotificationComponent'
import AIImage from '../../Assets/Images/Group47526.png'
import { driver } from "driver.js";
import InitialsAvatar from 'react-initials-avatar';
import EmpNotificationComponent from '../../Components/EmpNotificationComponent'


export const NewEmployeeDashbord = () => {
    let location = useLocation();

    const [data, setData] = useState<EmployeeDash>();
    const helper = Helper.getInstance();
    const [loading, setLoading] = useState<boolean>(true);
    const { user, getDetails } = useAuth();
    const navigate = useNavigate();

    const getDashDetails = async () => {
        try {
            setLoading(true);
            const res_data = await helper.getReq(helper.GET.EMPLOYEE_DASH);
            const { data } = res_data.data;

            setLoading(false);
            if (data) setData(data);
            else throw new Error();

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user) getDashDetails();
    }, [user]);

    const getOptionLabel = (val: string | number, opArr: any[]) => {
        // console.log(opArr)
        const op = opArr.find((x) => x.value == val);

        if (op) return op.label;

        return '';
    };

    const getSuffix = (value: number = 0) => {
        if (value && Number(value) > 2) return 's';
        return '';
    };

    const getPosLabel = (val: string | number, opArr: PositionsProject[]) => {
        // console.log(opArr)
        const op = opArr.find((x) => x.id == val);

        if (op) return op.position;

        return '';
    };

    const handleViewDetailsButton = (id: number, applyDone: boolean = false) => {
        let url = `/candidate-view-jobs/${id}`
        if (applyDone) url = `/candidate-view-jobs/${id}?applyDone=1`
        navigate(url, { replace: true });
    };

    const handleViewAllMatching = () => {
        navigate('/candidate-all-jobs', { replace: true });
    };

    const handleViewAllApplied = () => {
        navigate('/candidate-my-applications', { replace: true });
    };

    const updateGuidePrefrence = async () => {
        await helper.putReq(helper.PUT.UPDATE_EMP_GUIDE_PREF, {});
    };

    useEffect(() => {
        if (user?.isGuideGiven == false) {
            const driverObj = driver({
                showProgress: true,
                steps: [
                    { element: '.all-jobs-cst', popover: { title: 'All Jobs', description: 'Current Job Openings.' } },
                    { element: '.my-profile-cst', popover: { title: 'Your Profile', description: ' Access and Update Details to View Matching Jobs.' } },
                    { element: '.gen-ai-vetting-cst', popover: { title: 'Technical Skill Test', description: 'Complete Gen AI Vetting to Get Matched with High-Paying Full-Time Remote Jobs in the US' } },
                    { element: '.my-application-view-all', popover: { title: 'My Application', description: 'Browse my application jobs.' } },
                ]
            });
    
            driverObj.drive();
            updateGuidePrefrence();
            getDetails(false);
        }
    }, []);

    if (loading) return <p>Loading...</p>;
    
    return (
        <div id="content" className='pt-5'>
            <div className='top-navigation mb-5'>
                <EmpNotificationComponent />
                <h2>Dashboard</h2>
                <p>This dashboard will help you with number of jobs applied and interviews</p>
            </div>
            <div className='custom-display-manage-mbile-desk flex-wrap'>
                <div className='mb-4'>
                    <div className='card dahbord-cst-card all-jobs-cst p-3'>
                        <div className='d-flex' style={{ gap: "16px" }}>
                            <div>
                                <img src={BagIcon} className='img-fluid' />
                            </div>
                            <div>
                                <h3>{data?.allJobsCount || 0}</h3>
                                <h4>All jobs</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mb-4'>
                    <div className='card dahbord-cst-card p-3'>
                        <div className='d-flex' style={{ gap: "16px" }}>
                            <div>
                                <img src={MatchingJobsIcon} className='img-fluid' />
                            </div>
                            <div>
                                <h3>{data?.matchingJobsCount || 0}</h3>
                                <h4>Matching Jobs</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mb-4'>
                    <div className='card dahbord-cst-card p-3'>
                        <div className='d-flex' style={{ gap: "16px" }}>
                            <div>
                                <img src={JobsAppliedIcon} className='img-fluid' />
                            </div>
                            <div>
                                <h3>{data?.appliedJobsCount || 0}</h3>
                                <h4>Jobs Applied</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mb-4'>
                    <div className='card dahbord-cst-card p-3'>
                        <div className='d-flex' style={{ gap: "16px" }}>
                            <div>
                                <img src={InteviewSchduleIcon} className='img-fluid' />
                            </div>
                            <div>
                                <h3>{data?.interviewJobsCount || 0}</h3>
                                <h4>Interviews</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='d-flex justify-content-between flex-wrap mb-3 mt-5'>
                    <h3>Matching Jobs</h3>
                    <h5><a
                        href={''}
                        className='cst-primary-color text-decoration-underline matching-jobs-view-all'
                        onClick={() => handleViewAllMatching()}
                    >View All
                    </a>
                    </h5>
                </div>
                <div className='d-flex cst-scollbar-add'>
                    {data?.newJobs.map((job: PosReqProjected2) => (
                        <>
                            {/* <div className='matching-jobs-card pt-4 px-4 pb-0' key={job.id}> */}
                            <div className='matching-jobs-card p-3 mb-4' key={job.id}>
                                {/* <div className="d-flex justify-content-between formobile-all-jobs"> */}
                                <div className="d-flex cst-jobs-gap-cls">
                                    <div>
                                        {job?.company.imageUrl ?
                                            <img src={job?.company.imageUrl || DefaultImage} className='img-fluid' style={{ borderRadius: "50%", minWidth: "80px", maxWidth: "80px" }} /> :
                                            <InitialsAvatar name={job?.company?.name || ''} />
                                        }
                                        {/* <img src={job.company.imageUrl || DefaultImage} className='img-fluid' style={{ borderRadius: "50%", minWidth: "80px", maxWidth: "80px" }} /> */}
                                    </div>
                                    <div>
                                        <label className='cst-secondary-color'>Job Role</label>
                                        <h5>{getPosLabel(job.positionId, data.positions)}</h5>

                                        <label className='cst-secondary-color'>Company</label>
                                        <h5
                                            className="blurred"
                                            contentEditable={false}
                                            onMouseDown={(e) => e.preventDefault()}
                                            onContextMenu={(e) => e.preventDefault()}
                                            onSelect={(e) => e.preventDefault()}
                                        >{job.company.name}</h5>
                                    </div>
                                    <div>
                                        <label className='cst-secondary-color'>Location</label>
                                        <h5><img src={LocationIcon} />&nbsp;{getOptionLabel(job.officePolicy || 1, OFFICE_POLICY)} • {job.company.country}</h5>

                                        <label className='cst-secondary-color'>Experience Required</label>
                                        <h5><img src={WorkSuitcaseIcon} className='img-fluid' />&nbsp;{getOptionLabel(job.seniority || 1, EXPERIENCE_LEVEL)}</h5>
                                    </div>
                                </div>
                                <div className='col-12 text-center mt-2'>
                                    <button
                                        className='btn btn-view-details col-12'
                                        onClick={() => handleViewDetailsButton(job.id)}
                                    >View Details
                                    </button>
                                </div>
                            </div>
                            {/* <div className='matching-jobs-card p-4 mb-4'>
                                <div className="d-flex justify-content-between formobile-all-jobs">
                                    <div>
                                        <img src={WprpressImage} className='img-fluid' />
                                    </div>
                                    <div>
                                        <label className='cst-secondary-color'>Job Role</label>
                                        <h5>Product  Designer</h5>

                                        <label className='cst-secondary-color'>Company</label>
                                        <h5>Volkswagen</h5>
                                    </div>
                                    <div>
                                        <label className='cst-secondary-color'>Location</label>
                                        <h5><img src={LocationIcon} /> Remote • India</h5>

                                        <label className='cst-secondary-color'>Experience Required</label>
                                        <h5><img src={WorkSuitcaseIcon} className='img-fluid' /> 5 Years</h5>
                                    </div>
                                </div>
                                <div className='col-12 text-center mt-2'>
                                    <button className='btn btn-view-details col-12'>View Details</button>
                                </div>
                            </div> */}
                        </>

                    ))
                    }
                    {/* <div className='matching-jobs-card p-4 mb-4'>
                        <div className="d-flex justify-content-between formobile-all-jobs">
                            <div>
                                <img src={WprpressImage} className='img-fluid' />
                            </div>
                            <div>
                                <label className='cst-secondary-color'>Job Role</label>
                                <h5>Product  Designer</h5>

                                <label className='cst-secondary-color'>Company</label>
                                <h5>Volkswagen</h5>
                            </div>
                            <div>
                                <label className='cst-secondary-color'>Location</label>
                                <h5><img src={LocationIcon} /> Remote • India</h5>

                                <label className='cst-secondary-color'>Experience Required</label>
                                <h5><img src={WorkSuitcaseIcon} className='img-fluid' /> 5 Years</h5>
                            </div>
                        </div>
                        <div className='col-12 text-center mt-2'>
                            <button className='btn btn-view-details col-12'>View Details</button>
                        </div>
                    </div>
                    <div className='matching-jobs-card p-4 mb-4'>
                        <div className="d-flex justify-content-between formobile-all-jobs">
                            <div>
                                <img src={WprpressImage} className='img-fluid' />
                            </div>
                            <div>
                                <label className='cst-secondary-color'>Job Role</label>
                                <h5>Product  Designer</h5>

                                <label className='cst-secondary-color'>Company</label>
                                <h5>Volkswagen</h5>
                            </div>
                            <div>
                                <label className='cst-secondary-color'>Location</label>
                                <h5><img src={LocationIcon} /> Remote • India</h5>

                                <label className='cst-secondary-color'>Experience Required</label>
                                <h5><img src={WorkSuitcaseIcon} className='img-fluid' /> 5 Years</h5>
                            </div>
                        </div>
                        <div className='col-12 text-center mt-2'>
                            <button className='btn btn-view-details col-12'>View Details</button>
                        </div>
                    </div>
                    <div className='matching-jobs-card p-4 mb-4'>
                        <div className="d-flex justify-content-between formobile-all-jobs">
                            <div>
                                <img src={WprpressImage} className='img-fluid' />
                            </div>
                            <div>
                                <label className='cst-secondary-color'>Job Role</label>
                                <h5>Product  Designer</h5>

                                <label className='cst-secondary-color'>Company</label>
                                <h5>Volkswagen</h5>
                            </div>
                            <div>
                                <label className='cst-secondary-color'>Location</label>
                                <h5><img src={LocationIcon} /> Remote • India</h5>

                                <label className='cst-secondary-color'>Experience Required</label>
                                <h5><img src={WorkSuitcaseIcon} className='img-fluid' /> 5 Years</h5>
                            </div>
                        </div>
                        <div className='col-12 text-center mt-2'>
                            <button className='btn btn-view-details col-12'>View Details</button>
                        </div>
                    </div> */}
                </div>
                <div className='employee-white-card' style={{ background: "none" }}>
                    {data?.newJobs.length == 0 ? <h3 className='text-center mt-2'>Matching Jobs are Not Found</h3> : ""}
                </div>
            </div>
            <div>
                <div className='d-flex justify-content-between flex-wrap mb-3 mt-4'>
                    <h3>My Applications</h3>
                    <h5>
                        <a
                            href={''}
                            className='cst-primary-color text-decoration-underline my-application-view-all'
                            onClick={() => handleViewAllApplied()}
                        >View All
                        </a>
                    </h5>
                </div>
                {user?.isAllSkillsVetted ? (<>
                    <div className='d-flex cst-scollbar-add' style={{ gap: "16px" }}>
                        {data?.appliedJobs.map((job: PosReqProjected2) => (
                            <div className='matching-jobs-card p-3 mb-4' key={job.id}>
                                <div className="d-flex cst-jobs-gap-cls">
                                    <div>
                                        {job?.company.imageUrl ?
                                            <img src={job?.company.imageUrl || DefaultImage} className='img-fluid' style={{ borderRadius: "50%", minWidth: "80px", maxWidth: "80px" }} /> :
                                            <InitialsAvatar name={job?.company?.name || ''} />
                                        }
                                        {/* <img src={job.company.imageUrl || DefaultImage} className='img-fluid' style={{ borderRadius: "50%", minWidth: "80px", maxWidth: "80px" }} /> */}
                                    </div>
                                    <div>
                                        <label className='cst-secondary-color'>Job Role</label>
                                        <h5>{getPosLabel(job.positionId, data.positions)}</h5>

                                        <label className='cst-secondary-color'>Company</label>
                                        <h5
                                            className="blurred"
                                            contentEditable={false}
                                            onMouseDown={(e) => e.preventDefault()}
                                            onContextMenu={(e) => e.preventDefault()}
                                            onSelect={(e) => e.preventDefault()}
                                        >{job.company.name}</h5>
                                    </div>
                                    <div>
                                        <label className='cst-secondary-color'>Location</label>
                                        <h5><img src={LocationIcon} />&nbsp;{getOptionLabel(job.officePolicy || 1, OFFICE_POLICY)} • {job.company.country}</h5>

                                        <label className='cst-secondary-color'>Experience Required</label>
                                        <h5><img src={WorkSuitcaseIcon} className='img-fluid' />&nbsp;{getOptionLabel(job.seniority || 1, EXPERIENCE_LEVEL)}</h5>
                                    </div>
                                </div>
                                <div className='col-12 text-center mt-2'>
                                    <button
                                        className='btn btn-view-details col-12'
                                        onClick={() => handleViewDetailsButton(job.id, true)}
                                    >View Details
                                    </button>
                                </div>
                            </div>
                        ))}

                    </div>
                    <div className='employee-white-card' style={{ background: "none" }}>
                        {data?.appliedJobs.length == 0 ? <h3 className='text-center mt-2'>My Applications are Not Found</h3> : ""}
                    </div>
                </>) : (
                    <div className="card card-cst-bg-profile pt- pb-4">
                        <div className="d-flex flex-wrap align-items-center" style={{ justifyContent: "space-around" }}>
                            <div>
                                <img src={AIImage} className='img-fluid' />
                            </div>
                            <div className="text-white">
                                <h4 className='fw-bold'>Get certified and matched with jobs</h4>
                                <h5>Complete Gen AI vetting to stand out and apply for roles that align<br /> with your profile on Owow.</h5>
                            </div>
                            <div>
                                <button
                                    className="btn btn-complete-it-now px-4"
                                    onClick={() => navigate(`/candidate-gpt-vetting-page`, { replace: true })}
                                >Start now</button>
                            </div>
                        </div>
                    </div>

                )}
            </div>
        </div>
    )
}
