import NotificationComponent from '../../Components/NotificationComponent';
import LocationIcon from '../../Assets/Icons/LocationIcon.svg';
import WorkSuitcaseIcon from '../../Assets/Icons/material-symbols_work-outline.svg';
import CalenderIcon from '../../Assets/Icons/calendar-linear.svg';
import ClockIcon from '../../Assets/Icons/clockIcon.svg'
import DefaultComapnayImage from '../../Assets/Images/Image20240129183323.png';
import SPOCImage from '../../Assets/Images/SPOC.png';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { OptionType, PosReqData, PositionDetails } from '../../types';
import Helper from '../../utils/helper';
import { EXPERIENCE_LEVEL, OFFICE_POLICY } from '../../utils/constant';
import useAuth from '../../hooks/useAuth';
import { ApplyForJobModal } from '../../Components/Candidate/Modals/ApplyForJobModal';
import { toast } from 'react-toastify';
import InitialsAvatar from 'react-initials-avatar';
import EmpNotificationComponent from '../../Components/EmpNotificationComponent';

interface PosValidations {
    isApplied: boolean;
    skillsMatches: boolean;
    skillsResultMatches: boolean;
};

const EmployeeViewJobPage = () => {

    const navigate = useNavigate();

    const initPosValidations: PosValidations = {
        isApplied: false,
        skillsMatches: false,
        skillsResultMatches: false,
    };

    const [posValidations, setPosValidations] = useState<PosValidations>(initPosValidations);
    const [positions, setPositions] = useState<OptionType[]>([]);
    const [positionReq, setPositionReq] = useState<PosReqData>();
    const [loading, setLoading] = useState<boolean>(false);
    const [applied, setApplied] = useState<boolean>(false);
    const helper = Helper.getInstance();
    const { id } = useParams();
    const [searchParams] = useSearchParams();

    const { user, getDetails } = useAuth();

    useEffect(() => {
        getDetails(false);
    }, []);

    useEffect(() => {
        if (!id) navigate('/candidate-all-jobs', { replace: true });
        else if (user) {
            getPositionReqs(id);
            getPositionDetails();
            if (searchParams.get('applyDone') && Number(searchParams.get('applyDone')) == 1) setApplied(true);
        }
    }, [user]);

    const getPositionReqs = async (id2: string) => {
        try {
            setLoading(true);

            const res_data = await helper.getReq(helper.GET.POS_DETAILS + id2);
            const { data } = res_data.data;

            if (data) {
                setPositionReq(data.positionReq);
                setPosValidations({
                    isApplied: data.isApplied,
                    skillsMatches: data.skillsMatches,
                    skillsResultMatches: data.skillsResultMatches
                });
            }
            setLoading(false);
        } catch (error) {
            navigate('/candidate-all-jobs', { replace: true });
            console.log(error);
            setLoading(false);
        }
    };

    const getPositionDetails = async () => {
        try {
            // setLoading(true)
            const res_data = await helper.getReq(helper.GET.POSITIONS);
            const { data } = res_data.data;

            const mapped: OptionType[] = data.map((dt: PositionDetails) => ({ label: dt.position, value: dt.id }));
            if (data) setPositions(mapped);
            // setLoading(false)
        } catch (error) {
            console.log(error);
            // setLoading(false)
        }
    };

    const getOptionLabel = (val: string | number, opArr: any[]) => {
        const op = opArr.find((x) => x.value == val);

        if (op) return op.label;

        return '';
    };

    const notVetted = () => {
        toast.error('Complete Core Skills GPT Vetting First');
        setTimeout(() => {
            navigate(`/candidate-gpt-vetting-page`, { replace: true });
        }, 2000);
    };

    const sksIssue = () => {
        if (!posValidations.skillsMatches) toast.error('Results below 6. Reassess skills with scores under 6.');
        else if (!posValidations.skillsResultMatches) toast.error('Job skills do not match your profile.');
    }

    if (!user) return <p>Loading...</p>

    return (
        <>
            <div id="content" className='pt-5'>
                <div className="container-fluid">
                    <div className='top-navigation mb-5'>
                        <EmpNotificationComponent />
                        <h2>Jobs Details</h2>
                        <button className='btn view-details-btn px-4 mt-2' onClick={() => navigate('/candidate-all-jobs', { replace: true })}>Back</button>
                    </div>
                    <div className='row employee-white-card align-items-center p-4 mb-4'>
                        <div className='col-lg-1 col-md-2 col-sm-3'>
                            <div>
                                {positionReq?.company.imageUrl ?
                                    <img src={positionReq?.company.imageUrl || DefaultComapnayImage} className='img-fluid' style={{ borderRadius: "50%" }} /> :
                                    <InitialsAvatar name={positionReq?.company?.name || ''} />
                                }
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-5'>
                            <label className='cst-secondary-color'>Job Role</label>
                            <h3 className='black-darkish-color'>{getOptionLabel(positionReq?.positionId || 1, positions)}</h3>

                            <label className='cst-secondary-color'>Company</label>
                            <h3
                                className="blurred"
                                contentEditable={false}
                                onMouseDown={(e) => e.preventDefault()}
                                onContextMenu={(e) => e.preventDefault()}
                                onSelect={(e) => e.preventDefault()}
                            >{positionReq?.company.name}</h3>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-4'>
                            <label className='cst-secondary-color'>Location</label>
                            <h4 className='black-darkish-color'><img src={LocationIcon} /> {getOptionLabel(positionReq?.officePolicy || 1, OFFICE_POLICY)} • {positionReq?.companyAddres.country}</h4>

                            <label className='cst-secondary-color'>Experience Required</label>
                            <h4 className='black-darkish-color'><img src={WorkSuitcaseIcon} /> {getOptionLabel(positionReq?.seniority || 1, EXPERIENCE_LEVEL)}</h4>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-5'>
                            <label className='cst-secondary-color'>Shift Time</label>
                            <h4 className='black-darkish-color'><img src={ClockIcon} />&nbsp;{positionReq?.timezone}</h4>

                            <label className='cst-secondary-color'>Notice Period</label>
                            <h4 className='black-darkish-color'><img src={CalenderIcon} /> 2 Weeks</h4>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-3 mt-2'>
                            {user.isAllSkillsVetted ? (
                                (applied || posValidations.isApplied) ? (
                                    <button
                                        className='btn view-details-btn px-3'
                                        disabled
                                    >Applied
                                    </button>
                                ) : (
                                    posValidations.skillsMatches && posValidations.skillsResultMatches ? (
                                        <button
                                            className='btn view-details-btn px-3'
                                            data-bs-target="#ApplyForJobModalToggle"
                                            data-bs-toggle="modal"
                                            disabled={loading}
                                        >Apply for this job
                                        </button>
                                    ) : (
                                        <button
                                            className='btn view-details-btn px-3'
                                            onClick={() => sksIssue()}
                                        >Apply for this job
                                        </button>
                                    )
                                )
                            ) : (
                                <button
                                    className='btn view-details-btn px-3'
                                    onClick={() => notVetted()}
                                >Apply for this job
                                </button>
                            )}
                        </div>

                    </div>
                    <div className='row cst-all-jobs-description'>
                        <div className='col-md-8 col-12'>
                            <div className='employee-white-card p-4 mb-4'>
                                <h3 className='black-darkish-color'>Job Description</h3>
                                {positionReq?.description !== undefined && (
                                    <div className='black-para' dangerouslySetInnerHTML={{ __html: positionReq.description }} />
                                )}

                            </div>
                        </div>
                        <div className='col-md-4 col-12'>
                            <div className='employee-white-card p-4 mb-4'>
                                <h3 className='black-darkish-color'>Must have Skills</h3>
                                <div className='d-flex mt-2' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                    {positionReq?.skills.map((sk, index) => (
                                        <button className='btn cst-btn-tags' key={sk + index}>{sk}</button>
                                    ))}
                                </div>

                                <h3 className='black-darkish-color mt-4'>Good to have Skills</h3>
                                <div className='d-flex mt-2' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                    {positionReq?.optionalSkills.map((sk, index) => (
                                        <button className='btn cst-btn-tags' key={sk + index}>{sk}</button>
                                    ))}
                                </div>

                            </div>
                            <div className='employee-white-card p-4 mb-4'>
                                <h3 className='black-darkish-color'>Your SPOC</h3>
                                <div className='d-flex align-items-center mb-4' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                    <img src={user.SPOCIamgeURL || SPOCImage} alt='image' className='img-fluid' style={{ width: '20%', height: "20%" }} />
                                    <h2 className='mt-3'>{user?.SPOCName || 'Nathan A'}</h2>
                                </div>
                                <div>
                                    <label className='cst-secondary-color' htmlFor="monbilenumber">Mobile number</label>
                                    <p className='text-black'>{user?.SPOCPhone || '+1 925-319-7688'}</p>
                                </div>
                                <div>
                                    <label className='cst-secondary-color' htmlFor="emailid">Email id</label>
                                    <p className='text-black'>{user?.SPOCEmail || 'hi@owowtalents.com'}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {positions.length !== 0 && user && <ApplyForJobModal posId={positionReq?.id || 1} positions={positions} user={user} setApplied={setApplied} />}
        </>
    )
}

export default EmployeeViewJobPage