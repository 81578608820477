import { useState } from 'react';
import { OpenAI } from 'openai';

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, // Use environment variable for security
    dangerouslyAllowBrowser: true,
});

interface JDParams {
    coreSkills: string[];
    additionalSkills: string[];
    seniorityLevel: string;
    position: string;
}

export const useJobDescriptionGenerator = () => {
    const [jobDescription, setJobDescription] = useState<string | null>(null);
    const [loading5, setLoading5] = useState<boolean>(false);

    const generateJD = async (params: JDParams) => {
        setLoading5(true);

        const { coreSkills, additionalSkills, seniorityLevel, position } = params;

        const prompt = `
Generate a professional Job Description for the position of {seniorityLevel} {position}. 
Limit the total description to 600 words. 
The description should be returned as clean, semantic HTML that can be directly used as content for a rich text editor (ReactQuill). 
The Job Description should include the following sections:

- <strong>Brief Description</strong>: A short 2-3 line intro about the role and the impact on the company.
- <strong>The Role</strong>: Briefly explain what this person will do.
- <strong>Key Responsibilities</strong>: A bullet point list of 5-7 core tasks.
- <strong>Technical Requirements</strong>: This should include core skills: ${coreSkills.join(", ")}. Additional/nice-to-have skills: ${additionalSkills.join(", ")}.
- <strong>Qualifications</strong>: Education, experience, and certifications (if relevant).
- <strong>The Ideal Candidate Has</strong>: Soft skills and personality traits that would make someone excel in this role.

The tone should be professional but engaging. The HTML should be simple and clean, without unnecessary inline styles. 
The output must only be the HTML body content — no headers, footers, or explanations. 
`;

        try {
            const response = await openai.chat.completions.create({
                model: "gpt-4-turbo",
                messages: [{ role: "system", content: "You are a professional HR expert generating job descriptions." },
                { role: "user", content: prompt }],
                max_tokens: 1000,
                temperature: 0.7,
            });

            const jd = response.choices[0]?.message?.content ?? '';
            setJobDescription(jd);
        } catch (error) {
            console.error("Error generating JD:", error);
            setJobDescription("Failed to generate job description.");
        } finally {
            setLoading5(false);
        }
    };

    return { jobDescription, generateJD, loading5 };
};
