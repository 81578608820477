
import DefaultImage from '../../Assets/Icons/default.png';
import useAuth from '../../hooks/useAuth';
import WhatsappIcon from '../../Assets/Icons/whatsappIcon.png';
import SPOCImage from '../../Assets/Images/SPOC.png';
import NotificationComponent from '../../Components/NotificationComponent'
import EmpNotificationComponent from '../../Components/EmpNotificationComponent';

const EmployeeSpocPage = () => {
    const { user } = useAuth();

    if (!user) {
        return <p>Loading...</p>
    };

    return (
        <div id="content" className='pt-5'>
            <div className="container-fluid">
                <div className='top-navigation mb-5'>
                    <EmpNotificationComponent/>
                    <h2>Single Point of Contact</h2>
                    <p>This dashboard will help you in analysing your activities in various aspects.</p>
                </div>
                <div className='row mb-5 align-items-center'>
                    <div className='d-flex flex-wrap' style={{ gap: "16px" }}>
                        <div>
                            <img src={user.SPOCIamgeURL || SPOCImage} alt='image' className='img-fluid' style={{ width: '80px', height: '80px', borderRadius: "50%" }} />
                        </div>
                        <div>
                            <h1>{user.SPOCName || 'Nathan A'}</h1>
                            <p className='text-black'>{user.SPOCRole || 'Talent Manager'}</p>
                        </div>
                    </div>
                    <div className='card font-size-small-div p-3 mt-5' style={{
                        borderRadius: "36px",
                        background: "rgb(255, 255, 255)",
                        border: "none",
                    }}>
                        <div className='row m-3'>
                            <h2>Overview</h2>
                            <div className='col-sm-3 mt-4'>
                                <label className='overview-sec-label'>Mobile Number</label>
                                <h4>{user.SPOCPhone || '+1 408-479-4895'}</h4>
                            </div>
                            <div className='col mt-4'>
                                <label className='overview-sec-label'>Email Id</label>
                                <h4>{user.SPOCEmail || 'hi@owowtalents.com'}</h4>
                            </div>
                            <div className='col mt-4'>
                                <button
                                    className='btn btn-whatsapp-btn-cst  p-2 d-flex align-items-center'
                                ><a href={`http://wa.me/${user.SPOCWpPhone || '+14084794895'}`} target='_blank'>
                                        <img
                                            src={WhatsappIcon}
                                            className='img-fluid'
                                        /> Chat with {user.SPOCName || 'Nathan A'}
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}

export default EmployeeSpocPage