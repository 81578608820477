import { useEffect, useState } from 'react'
import NotificationComponent from '../../Components/NotificationComponent'
import Select from 'react-select';
import MapPinIcon from '../../Assets/Icons/map-pin.svg'
import LaunguageIcon from '../../Assets/Icons/tabler_language.svg'
import DotImg from '../../Assets/Icons/EllipseDot.svg'
import { AddSkillsModal } from '../../Components/Candidate/Modals/AddSkillsModal'
import EditIcon from '../../Assets/Icons/EditIcon.svg'
import EditIconSmall from '../../Assets/Icons/EditVectorSmall.png'
import TrashIcon from '../../Assets/Icons/trash.png'
import { AddExperienceModal } from '../../Components/Candidate/Modals/AddExperienceModal'
import { AddEducation } from '../../Components/Candidate/Modals/AddEducation';
import { AddCertificationsModal } from '../../Components/Candidate/Modals/AddCertificationsModal';
import useAuth from '../../hooks/useAuth';
import Helper from '../../utils/helper';
import { Certification, Education, Experience, JobPreferences, ObjectMap, OptionType, PositionDetails, ProfileDetails, SkillData } from '../../types';
import { EMP_STATUS, EXPERIENCE_LEVEL, OFFICE_POLICY, POSITION_TYPES, SKILL_TYPES, TIMEZONES, countries, customStyles, languagesOptions } from '../../utils/constant';
import React from 'react';
import { toast } from 'react-toastify';

import DefaultImage from '../../Assets/Images/uploadImage.png';
import DefaultComanayImage from '../../Assets/Images/Image20240129183323.png';
import { ProfileModal } from '../../Components/Candidate/Modals/ProfileModal';
import DeleteCertificationModal from '../../Components/Candidate/Modals/DeleteCertificationModal';
import { AddOpsSkillsModal } from '../../Components/Candidate/Modals/AddOpsSkillsModal';
import DeleteIconModal from '../../Assets/Icons/deleteIconModal.png'
import CurrencyInput from 'react-currency-input-field';
import EmpNotificationComponent from '../../Components/EmpNotificationComponent';


const EmployeeProfilePage = () => {
    const [activeTab, setActiveTab] = useState("ExperienceandSkill");
    const [editToogle, setEditToogle] = useState(false);
    const { user, getDetails } = useAuth();
    const helper = Helper.getInstance();
    const [loading, setLoading] = useState<boolean>(false);
    const [profile, setProfile] = useState<ProfileDetails>();
    const [positions, setPositions] = useState<PositionDetails[]>([]);
    const [positionsOP, setPositionsOP] = useState<OptionType[]>([]);

    const initData: JobPreferences = {
        officePolicys: [],
        timezone: '',
        positionType: 1,
        noticePeriod: null,
        positionIds: [],
        currentPASalary: null,
        expectedPASalary: null
    };
    const [jobPref, setJobPref] = useState<JobPreferences>(initData);

    const [exp, setExp] = useState<Experience | null>(null);
    const [edu, setEdu] = useState<Education | null>(null);
    const [cert, setCert] = useState<Certification | null>(null);
    const [prefix, setPrefix] = useState<string>('$    ');
    const [skillsDB, setSkillsDB] = useState<OptionType[]>([]);

    useEffect(() => {
        if (user) {
            getProfileDetails();
            getPositionDetails();
            getSkillsDataDB();
        }
    }, [user]);

    const getProfileDetails = async () => {
        try {
            setLoading(true)
            const res_data = await helper.getReq(helper.GET.EMP_PROFILES);
            const { data } = res_data.data;

            setLoading(false);
            if (data) {
                setProfile(data);

                const prefData: JobPreferences = {
                    officePolicys: data.skAndExp.officePolicys,
                    timezone: data.skAndExp.timezone,
                    positionType: data.empStatus.positionType,
                    noticePeriod: data.empStatus.noticePeriod,
                    positionIds: data.skAndExp.positionIds,
                    currentPASalary: data.empStatus.currentPASalary,
                    expectedPASalary: data.empStatus.expectedPASalary
                };

                setJobPref(prefData);
            };
            // console.log(profile);
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    useEffect(() => {
        if (user) setPrefix(user.workingCountry == 'IN' ? '₹    ' : '$    ');
    }, [user]);

    const getPositionDetails = async () => {
        try {
            const res_data = await helper.getReq(helper.GET.POSITIONS);
            const { data } = res_data.data;

            if (data) {
                setPositions(data);

                const mapped: OptionType[] = data.map((dt: PositionDetails) => ({ label: dt.position, value: dt.id }));
                setPositionsOP(mapped);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getSkillsDataDB = async () => {
        try {
            const res_data = await helper.getReq(helper.GET.SKILLS_DATA);
            const { data } = res_data.data;
            if (data) {
                const mapped: OptionType[] = data.map((sk: SkillData) => ({ label: sk.skill, value: sk.skill }));
                setSkillsDB(mapped);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleExpirenceAndSkills = () => {
        setActiveTab("ExperienceandSkill")
    };

    const handleJobPreference = () => {
        setActiveTab("JobPreference")
    };

    const handleEducationsAndCertificates = () => {
        setActiveTab("EducationsAndCertificates")
    }

    const handleAssessments = () => {
        setActiveTab("Assessments")
    }

    const getOptionLabel = (val: string | number, opArr: any[]) => {
        const op = opArr.find((x) => x.value == val);

        if (op) return op.label;

        return '';
    };

    const getPOSLabel = (val: string | number) => {
        const pos = positions.find((x) => x.id == val);

        if (pos) return pos.position;

        return '';
    };

    const [daleteData, setDaleteData] = useState<{ id: number, type: number }>();

    const deleteItem = async (type: number, id: number) => {
        setDaleteData({ id, type });
    };

    const deleteConfirm = async () => {
        try {
            setLoading(true);
            // const confirmed = window.confirm("Are you sure you want to delete");

            if (!daleteData) return;

            let url = '';

            switch (daleteData.type) {
                case 1:
                    url = helper.DELETE.DELETE_EXPERIENCE + daleteData.id;
                    break;
                case 2:
                    url = helper.DELETE.DELETE_EDUCATION + daleteData.id;
                    break;
                case 3:
                    url = helper.DELETE.DELETE_CERTIFICATION + daleteData.id;
                    break;
                default:
                    throw new Error('Failed');
            };

            const res_data = await helper.deleteReq(url, {});
            const { data } = res_data.data;

            setLoading(false);
            if (data) {
                document.getElementById('cancelId')?.click();
                getDetails(false);
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const editItem = async (type: number, data: any) => {
        switch (type) {
            case 1:
                setExp(data);
                // setTimeout(() => {
                document.getElementById('AddExpirenceModalToggleID')?.click();
                // }, 2000)
                break;
            case 2:
                setEdu(data);
                document.getElementById('AddEducationModalToggleID')?.click();
                break;
            case 3:
                setCert(data);
                document.getElementById('AddCertificationsModalToggleID')?.click();
                break;
            default:
                break;
        };
    };

    const getMultiSelectedDef = (dataArr: OptionType[], dataVal: number[] | string[]) => {
        if (!dataVal.length) return;

        const jdataArr: OptionType[] = JSON.parse(JSON.stringify(dataArr));
        const newArr = [];

        for (let d of dataVal) {
            const val = jdataArr.filter((dt) => dt.value == d);

            if (val.length) newArr.push(val[0]);
        };

        return newArr;
    };

    const handleSelectChange = (keyType: number, value: any) => {
        // console.log(value);

        switch (keyType) {
            case 1:
                let arr = value.map((d: any) => d.value);
                setJobPref({ ...jobPref, officePolicys: arr });
                break;
            case 2:
                let arr2 = value.map((d: any) => d.value);
                setJobPref({ ...jobPref, timezone: arr2 });
                // jobPref.timezones = arr;
                break;
            case 3:
                let arr3 = value.map((d: any) => d.value);
                setJobPref({ ...jobPref, positionIds: arr3 });
                // jobPref.positionIds = arr;
                break;
            case 4:
                let poss = JSON.parse(JSON.stringify([...jobPref.positionIds]));
                poss[0] = value;
                setJobPref({ ...jobPref, positionIds: poss });

                break;
            default:
                break;
        }

        // setJobPref(jobPref);
    };

    const handleSubmit = async (ev?: any) => {
        // ev.preventDefault();
        setLoading(true);
        try {
            const res_data = await helper.putReq(helper.PUT.UPDATE_JOB_PREFRENCES, jobPref);
            const { data } = res_data.data;

            if (data) {
                getProfileDetails();
                setLoading(false);
                setEditToogle(false);
                toast.success('Updated Successfully');
            }
            else {
                setLoading(false);
                setEditToogle(false);
                throw new Error("Failed");
            }
        } catch (error) {
            setLoading(false);
            setEditToogle(false);
            console.log(error);
            toast.error('Update Failed');
        }
    };

    if (!user) return <p>Loading...</p>;

    return (
        <>
            <div id="content" className='pt-5'>
                <div className="container-fluid">
                    <div className='top-navigation mb-5'>
                        <EmpNotificationComponent />
                        <h2>My Profile</h2>
                        <p >Update or edit your profile information to get matched with right jobs</p>
                    </div>
                    <div className='d-flex mobile-two-buttons' style={{ gap: "20px" }}>
                        <div>
                            <img src={user?.imageUrl || DefaultImage} className='img-fluid image-for-profile-cst' />
                        </div>
                        <div className='employee-my-profile'>
                            <div className='d-flex justify-content-between mobile-two-buttons mb-2'>
                                <h2>{user?.name}</h2>
                                <div className='d-flex flex-wrap' style={{ gap: "18px" }}>
                                    <button className='btn btn-tag-senior'>
                                        {getOptionLabel(profile?.skAndExp.seniority || 1, EXPERIENCE_LEVEL)}
                                    </button>
                                    <button className='btn btn-tag-final-interview px-3'>{user?.gender}</button>
                                    <button className='btn add-skill-right-button' data-bs-target="#ProfileModalToggle" data-bs-toggle="modal"><img src={EditIcon} className='img-fluid' /> Edit</button>
                                </div>
                            </div>
                            <h4>
                                <span style={{ fontWeight: "400" }}>{getPOSLabel(profile?.skAndExp.positionIds[0] || 1)}
                                    &nbsp;&nbsp;
                                    <img className="img-fluid" src={MapPinIcon} />{getOptionLabel(user?.country || 'US', countries)}
                                    &nbsp;&nbsp;
                                    <img className="img-fluid" src={LaunguageIcon} />{getOptionLabel(profile?.skAndExp.languages[0].language || 'English', languagesOptions)}<br />
                                </span>
                                {profile?.skAndExp.relativeExp && (<span style={{ fontWeight: "500" }}>{profile.skAndExp.relativeExp} {profile.skAndExp.relativeExp > 1 ? 'years' : 'year'}</span>)}
                            </h4>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <ul className="my-account-tabs-list">
                            <li><a href="#" className={`${activeTab == "ExperienceandSkill" && 'active'}`} onClick={handleExpirenceAndSkills}>Experience and Skill</a></li>
                            <li><a href="#" className={`${activeTab == "JobPreference" && 'active'}`} onClick={handleJobPreference}>Job Preference</a></li>
                            <li><a href="#" className={`${activeTab == "EducationsAndCertificates" && 'active'}`} onClick={handleEducationsAndCertificates}>Educations and Certificates</a></li>
                            {/* <li><a href="#" className={`${activeTab == "Assessments" && 'active'}`} onClick={handleAssessments}>Assessments</a></li> */}
                        </ul>
                    </div>
                    {activeTab === "ExperienceandSkill" && <>
                        <div className='employee-white-card p-4'>
                            <div className='d-flex justify-content-between align-iteams-center flex-wrap mb-4'>
                                <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>Core Technical Skills</h5>
                                <button className='btn add-skill-right-button' data-bs-target="#AddSkillsModalToggle" data-bs-toggle="modal">Update Core Technical Skills</button>
                            </div>
                            <div className='d-flex mt-2' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                {
                                    profile?.empSkills.map((sk, index) => {
                                        if (sk.skillType == SKILL_TYPES.MAIN) {
                                            return (
                                                // <button className='btn cst-btn-tags' key={index}>
                                                // </button>
                                                <button className='btn cst-btn-tags' key={index}>
                                                    {sk.skill}
                                                    <span className="cst-primary-color">
                                                        &nbsp;
                                                        <img src={DotImg} className='img-fluid' />
                                                        &nbsp;
                                                        {sk.experience} years
                                                    </span> </button>
                                            )
                                        }
                                    })
                                }
                            </div>
                            {profile?.empSkills.filter((sk => sk.skillType == SKILL_TYPES.MAIN)).length == 0 ? <h3 className='text-center mt-2'>Add your Core Technical Skills</h3> : ""}

                        </div>

                        <div className='employee-white-card p-4 mt-4'>
                            <div className='d-flex justify-content-between align-iteams-center flex-wrap mb-4'>
                                <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>Additional Skills</h5>
                                <button className='btn add-skill-right-button' data-bs-target="#AddOpsSkillsModalToggle" data-bs-toggle="modal">Update Additional Skills</button>
                            </div>
                            <div className='d-flex mt-2' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                {
                                    profile?.empSkills.map((sk, index) => {
                                        if (sk.skillType == SKILL_TYPES.ADDITIONAL) {
                                            return (
                                                // <button className='btn cst-btn-tags' key={index}>
                                                // </button>
                                                <button className='btn cst-btn-tags' key={index}>
                                                    {sk.skill}
                                                    <span className="cst-primary-color">
                                                        &nbsp;
                                                        <img src={DotImg} className='img-fluid' />
                                                        &nbsp;
                                                        {sk.experience} years
                                                    </span> </button>
                                            )
                                        }
                                    })
                                }
                            </div>
                            {profile?.empSkills.filter((sk => sk.skillType == SKILL_TYPES.ADDITIONAL)).length == 0 ? <h3 className='text-center mt-2'>Add your Additional Skills</h3> : ""}
                            {/* {profile?.skAndExp.additionalSkills.length == 0 ? <h3 className='text-center mt-2'>Add your Additional Skills</h3> : ""} */}
                        </div>

                        <div className='employee-white-card p-4 mt-4'>
                            <div className='d-flex justify-content-between align-iteams-center flex-wrap mb-4'>
                                <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>Experience</h5>
                                <button id='AddExpirenceModalToggleID' className='btn add-skill-right-button' data-bs-target="#AddExpirenceModalToggle" data-bs-toggle="modal" >+ Add </button>
                            </div>
                            <div className='row'>
                                <div className='col-md-9 col-12'>
                                    {profile !== undefined && profile.experience.length > 0 && profile.experience.map((exp) => (
                                        <React.Fragment key={exp.id}>
                                            <div className='d-flex justify-content-between flex-wrap align-items-center mt-2'>
                                                <div className='d-flex flex-wrap' style={{ gap: "16px" }}>
                                                    <div>
                                                        <img className='img-fluid' style={{ width: '60px', height: '60px', borderRadius: "50%" }} src={DefaultComanayImage} />
                                                    </div>
                                                    <div>
                                                        <h5>{exp.role}&nbsp;&nbsp;
                                                            <img
                                                                onClick={() => deleteItem(1, exp.id || 1)}
                                                                src={TrashIcon} style={{ cursor: 'pointer' }}
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#DeleteCertificationseModal"
                                                            />&nbsp;&nbsp;
                                                            <img src={EditIconSmall} className='img-fluid' onClick={() => editItem(1, exp)} style={{ cursor: 'pointer' }} />
                                                        </h5>
                                                        <label>{exp.company}</label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>{`01/${exp.startMonth}/${exp.startYear}`} &nbsp;-&nbsp;   {exp.currentlyWorking ? 'Present' : `01/${exp.endMonth}/${exp.endYear}`}  </p>
                                                    <label>{exp.totalExp}</label>
                                                </div>
                                            </div>
                                            {profile !== undefined && profile.experience.length > 0 && <hr />}
                                        </React.Fragment>
                                    ))}
                                </div>
                                {profile !== undefined && profile.experience.length == 0 ? <h3 className='text-center mt-2'>Add your work experience.</h3> : ""}
                            </div>
                        </div>

                    </>}

                    {activeTab === "JobPreference" && (
                        <>
                            <div className='text-end'>
                                {!editToogle ? (
                                    <button
                                        id='222'
                                        className='btn add-skill-right-button mb-4'
                                        onClick={() => setEditToogle(!editToogle)}
                                        type="button"
                                    ><img src={EditIcon} className='img-fluid'
                                        />&nbsp;Edit
                                    </button>
                                ) : (
                                    <button
                                        id='3333'
                                        className='btn add-skill-right-button mb-4'
                                        onClick={() => handleSubmit()}
                                        disabled={loading}
                                        type='submit'
                                    >{loading ? 'Saving...' : 'Save'}
                                    </button>
                                )}
                            </div>
                            {!editToogle ? <>
                                <div className='employee-white-card p-4'>
                                    <div className='d-flex justify-content-between align-iteams-center mb-4'>
                                        <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>Working Type</h5>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 col-12'>
                                            <h6 className='cst-secondary-color' style={{ fontWeight: "600" }}>Method of Working</h6>
                                            <div className='d-flex flex-wrap' style={{ gap: "12px" }}>
                                                {
                                                    profile?.skAndExp.officePolicys.map((pol) => (
                                                        <button className='btn btn-blue-btn-tag'>
                                                            {getOptionLabel(pol, OFFICE_POLICY)}
                                                        </button>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <h6 className='cst-secondary-color' style={{ fontWeight: "600" }}>Preferred working hours</h6>
                                            <div className='d-flex flex-wrap' style={{ gap: "12px" }}>
                                                {/* {
                                                    profile?.skAndExp.timezones.map((tz) => ( */}
                                                <button className='btn btn-blue-btn-tag'>
                                                    {getOptionLabel(profile?.skAndExp.timezone || '', TIMEZONES)}
                                                </button>
                                                {/* ))
                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='employee-white-card p-4 mt-4'>
                                    <div className='d-flex justify-content-between align-iteams-center mb-4'>
                                        <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>Job Status</h5>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <h6 className='cst-secondary-color' style={{ fontWeight: "600" }}>Current Employment Status</h6>
                                            <h5>{getOptionLabel(profile?.empStatus.positionType || 1, POSITION_TYPES)}</h5>
                                        </div>
                                        <div className='col-md-4'>
                                            <h6 className='cst-secondary-color' style={{ fontWeight: "600" }}>Notice Period</h6>
                                            <h5>{profile?.empStatus.noticePeriod || 1} Days</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='employee-white-card p-4 mt-4'>
                                    <div className='d-flex justify-content-between align-iteams-center mb-4'>
                                        <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>Job Role</h5>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <h6 className='cst-secondary-color' style={{ fontWeight: "600" }}>Current Role</h6>
                                            <h5>{getPOSLabel(profile?.skAndExp.positionIds[0] || 1)}</h5>
                                        </div>
                                        <div className='col-md-4'>
                                            <h6 className='cst-secondary-color' style={{ fontWeight: "600" }}>Preferred Job Role</h6>
                                            <div className='d-flex flex-wrap' style={{ gap: "12px" }}>
                                                {
                                                    profile?.skAndExp.positionIds.map((ps) => (
                                                        <button className='btn btn-blue-btn-tag'>
                                                            {getPOSLabel(ps)}
                                                        </button>
                                                    ))
                                                }
                                                {/* <button className='btn btn-blue-btn-tag'>Product Designer</button>
                                                <button className='btn btn-blue-btn-tag'>UI UX Designer</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='employee-white-card p-4 mt-4'>
                                    <div className='d-flex justify-content-between align-iteams-center mb-4'>
                                        <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>Compensation</h5>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <h6 className='cst-secondary-color' style={{ fontWeight: "600" }}>Current Annual Salary</h6>
                                            <h5>{user.workingCountry == 'IN' ? '₹' : '$'} {profile?.empStatus.currentPASalary} / annum</h5>
                                        </div>
                                        <div className='col-md-4'>
                                            <h6 className='cst-secondary-color' style={{ fontWeight: "600" }}>Expected Annual Salary</h6>
                                            <h5>{user.workingCountry == 'IN' ? '₹' : '$'} {profile?.empStatus.expectedPASalary} / annum</h5>

                                        </div>
                                    </div>
                                </div>
                            </> : <>                        {/* Job Preference End */}
                                <div className='employee-white-card p-4'>
                                    <div className='d-flex justify-content-between align-iteams-center mb-4'>
                                        <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>Working Type</h5>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-5'>
                                            <div>
                                                <label>Method of Working</label>
                                                <Select
                                                    styles={customStyles}
                                                    className="mb-4"
                                                    placeholder="Select"
                                                    name='preferredWork'
                                                    options={OFFICE_POLICY}
                                                    value={getMultiSelectedDef(OFFICE_POLICY, jobPref.officePolicys || [])}
                                                    onChange={(ev) => handleSelectChange(1, ev)}
                                                    isMulti
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                            <div>
                                                <label>Preferred working hours</label>
                                                <Select
                                                    styles={customStyles}
                                                    className="mb-4"
                                                    placeholder="Select"
                                                    name='preferredWork'
                                                    options={TIMEZONES}
                                                    // value={getMultiSelectedDef(TIMEZONES, jobPref.timezones || [])}
                                                    value={TIMEZONES.filter((ct) => ct.value == jobPref.timezone)[0]}
                                                    // onChange={(ev) => handleSelectChange(2, ev)}
                                                    onChange={(ev) => setJobPref({ ...jobPref, timezone: ev?.value || '' })}
                                                    required
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='employee-white-card p-4 mt-4'>
                                    <div className='d-flex justify-content-between align-iteams-center mb-4'>
                                        <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>Job Status</h5>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-5'>
                                            <div>
                                                <label>Current Employment Status</label>

                                                <Select
                                                    styles={customStyles}
                                                    className="mb-4"
                                                    placeholder="Select"
                                                    name='JobRole'
                                                    options={EMP_STATUS}
                                                    value={EMP_STATUS.filter((ct) => ct.value == jobPref.positionType)[0]}
                                                    onChange={(ev) => setJobPref({ ...jobPref, positionType: ev?.value || 1 })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                            <div>
                                                <label>Notice Period</label>
                                                <input
                                                    type="number"
                                                    className="form-control custom-input"
                                                    placeholder="In Days"
                                                    value={jobPref.noticePeriod || undefined}
                                                    onChange={(val) => setJobPref({ ...jobPref, noticePeriod: Number(val.target.value) })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='employee-white-card p-4 mt-4'>
                                    <div className='d-flex justify-content-between align-iteams-center mb-4'>
                                        <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>Job Role</h5>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-5'>
                                            <div>
                                                <label>Current Role</label>
                                                <Select
                                                    name='worktype'
                                                    styles={customStyles}
                                                    className="mb-4"
                                                    placeholder="Select"
                                                    options={positionsOP}
                                                    value={positionsOP.filter((ct) => ct.value == jobPref.positionIds[0])[0]}
                                                    onChange={(ev) => handleSelectChange(4, ev?.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                            <div>
                                                <label>Preferred Job Role</label>
                                                <Select
                                                    styles={customStyles}
                                                    className="mb-4"
                                                    placeholder="Select"
                                                    name='JobRole'
                                                    options={positionsOP}
                                                    value={getMultiSelectedDef(positionsOP, jobPref.positionIds || [])}
                                                    onChange={(ev) => handleSelectChange(3, ev)}
                                                    isMulti
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='employee-white-card p-4 mt-4'>
                                    <div className='d-flex justify-content-between align-iteams-center mb-4'>
                                        <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>Compensation</h5>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-5'>
                                            <div>
                                                <label>Current Annual Salary</label>
                                                <div className='FieldPlaceholderWrap'>
                                                    <CurrencyInput
                                                        // type='text'
                                                        id="input-example"
                                                        name="input-name"
                                                        placeholder={`${prefix} Current annual salary`}
                                                        required
                                                        prefix={prefix}
                                                        className="form-control custom-input"
                                                        value={jobPref.currentPASalary || undefined}
                                                        decimalsLimit={2}
                                                        onValueChange={(val) => setJobPref({ ...jobPref, currentPASalary: Number(val) })}
                                                    />
                                                    <span>/&nbsp;annum</span>
                                                </div>
                                                {/* <input
                                                    type="number"
                                                    className="form-control custom-input"
                                                    placeholder="In k$"
                                                    value={jobPref.currentPASalary || undefined}
                                                    onChange={(val) => setJobPref({ ...jobPref, currentPASalary: Number(val.target.value) })}
                                                    required
                                                /> */}

                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                            <div>
                                                <label>Expected Annual Salary</label>
                                                <div className='FieldPlaceholderWrap'>
                                                    <CurrencyInput
                                                        // type='text'
                                                        id="input-example"
                                                        name="input-name"
                                                        placeholder={`${prefix} Current annual salary`}
                                                        required
                                                        prefix={prefix}
                                                        className="form-control custom-input"
                                                        value={jobPref.expectedPASalary || undefined}
                                                        decimalsLimit={2}
                                                        onValueChange={(val) => setJobPref({ ...jobPref, expectedPASalary: Number(val) })}
                                                    />
                                                    <span>/&nbsp;annum</span>
                                                </div>
                                                {/* <input
                                                    type="number"
                                                    className="form-control custom-input"
                                                    placeholder="In k$"
                                                    value={jobPref.expectedPASalary || undefined}
                                                    onChange={(val) => setJobPref({ ...jobPref, expectedPASalary: Number(val.target.value) })}
                                                    required
                                                /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>}
                        </>
                    )}

                    {activeTab === "EducationsAndCertificates" && <>
                        <div className='employee-white-card p-4'>
                            <div className='d-flex justify-content-between align-iteams-center mb-4'>
                                <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>Education</h5>
                                <button
                                    id='AddEducationModalToggleID'
                                    className='btn add-skill-right-button'
                                    data-bs-target="#AddEducationModalToggle"
                                    data-bs-toggle="modal"
                                >+ Add
                                </button>
                            </div>
                            <div className='d-flex flex-wrap' style={{ gap: "60px" }}>
                                {
                                    profile !== undefined && profile.education.map((edu) => (
                                        <div key={edu.id}>
                                            <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>{edu.degree}</h5>
                                            <h6>{edu.school}&nbsp;
                                                <img
                                                    onClick={() => deleteItem(2, edu.id || 1)}
                                                    style={{ cursor: 'pointer' }}
                                                    src={TrashIcon}
                                                    className='img-fluid'
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#DeleteCertificationseModal"
                                                />&nbsp;&nbsp;
                                                <img
                                                    onClick={() => editItem(2, edu)}
                                                    style={{ cursor: 'pointer' }}
                                                    src={EditIconSmall}
                                                    className='img-fluid'
                                                />
                                            </h6>
                                            <h6>{edu.startYear} – {edu.endYear}</h6>
                                        </div>
                                    ))
                                }
                                {/* <div>
                                    <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>BBA</h5>
                                    <h6>Gujarat University <img src={TrashIcon} />&nbsp;&nbsp;<img src={EditIconSmall} className='img-fluid' /> </h6>
                                    <h6>3412354513 | Credential Link<br />2017 – 2020 </h6>
                                </div>
                                <div>
                                    <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>BBA</h5>
                                    <h6>Gujarat University <img src={TrashIcon} />&nbsp;&nbsp;<img src={EditIconSmall} className='img-fluid' /> </h6>
                                    <h6>3412354513 | Credential Link<br />2017 – 2020 </h6>
                                </div> */}
                            </div>
                            {profile !== undefined && profile.education.length == 0 ? <h3 className='text-center mt-2'>Add your educational information</h3> : ""}

                        </div>
                        <div className='employee-white-card p-4 mt-4'>
                            <div className='d-flex justify-content-between align-iteams-center mb-4'>
                                <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>Certifications</h5>
                                <button
                                    id='AddCertificationsModalToggleID'
                                    className='btn add-skill-right-button'
                                    data-bs-target="#AddCertificationsModalToggle"
                                    data-bs-toggle="modal"
                                >+ Add
                                </button>
                            </div>
                            <div className='d-flex flex-wrap' style={{ gap: "60px" }}>
                                {
                                    profile !== undefined && profile.certification.map((ct) => (
                                        <div key={ct.id}>
                                            <h5 style={{ color: "#4F4F4F", fontWeight: "600" }}>{ct.courseName}</h5>

                                            <h6>{ct.orgName}&nbsp;
                                                <img
                                                    src={TrashIcon}
                                                    onClick={() => deleteItem(3, ct.id || 1)}
                                                    style={{ cursor: 'pointer' }}
                                                    className='img-fluid'
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#DeleteCertificationseModal"
                                                />&nbsp;&nbsp;
                                                <img
                                                    onClick={() => editItem(3, ct)}
                                                    style={{ cursor: 'pointer' }}
                                                    src={EditIconSmall}
                                                    className='img-fluid'
                                                />
                                            </h6>
                                            <h6>{ct.credentialId && `${ct.credentialId} |`}  {ct.credentialUrl && <a className='credentialUrl-cst' href={ct.credentialUrl || '#'} target='_blank'>Credential Link</a>}<br />{ct.issueDate} – {ct.expirationDate || 'Present'}</h6>
                                        </div>
                                    ))
                                }

                            </div>
                            {profile !== undefined && profile.certification.length == 0 ? <h3 className='text-center mt-2'>Add your Certifications</h3> : ""}
                        </div>

                    </>}
                </div>
            </div >
            {profile && profile.skAndExp.languages.length > 0 && (
                <ProfileModal
                    seniority={profile.skAndExp.seniority || 1}
                    languages={profile.skAndExp.languages || []}
                />
            )}
            {profile && skillsDB.length && (
                <AddSkillsModal skills={profile?.empSkills.filter((sk) => sk.skillType == SKILL_TYPES.MAIN) || []} skillsDB={skillsDB || []} />
            )}
            {profile && skillsDB.length && (
                <AddOpsSkillsModal skills={profile?.empSkills.filter((sk) => sk.skillType == SKILL_TYPES.ADDITIONAL) || []} skillsDB={skillsDB || []} />
            )}

            <AddExperienceModal experience={exp} />
            <AddEducation education={edu} />
            <AddCertificationsModal certification={cert} />
            <button id="updateView" className='d-none' onClick={() => getProfileDetails()}></button>
            <button id="updateView2" className='d-none' onClick={() => getDetails(false)}></button>
            <button id="updatePr" className='d-none' onClick={() => getProfileDetails()}></button>

            <div className="modal fade" id="DeleteCertificationseModal" tabIndex={-1} aria-labelledby="DeleteCertificationseModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered" >
                    <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                        <div className="modal-header">
                            <button type="button" className="btn-close" id='cancelId' data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body delete-modal">
                            <div className='text-center'>
                                <img src={DeleteIconModal} className='img-fluid mb-2' />
                                <h4><b>Are you sure you want to Delete?</b></h4>
                                <div className='d-flex justify-content-center'>
                                    <label className='cst-secondary-color mt-2'>If you delete it will be gone forever, Are you<br /> sure you want to go ahead and delete</label>
                                </div>
                                <div className='d-flex mt-4 chnagepasss-cst-col' style={{ gap: "16px", justifyContent: "center" }}>
                                    <button type='button' className='btn social-media-btn' data-bs-dismiss="modal" aria-label="Close"
                                        style={{ minWidth: "139px" }} >Cancel</button>
                                    <button
                                        type='button'
                                        className='btn create-account-btn'
                                        style={{ minWidth: "139px" }}
                                        onClick={() => deleteConfirm()}
                                    >Delete </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeProfilePage